import Chip from "@mui/material/Chip";

import { MdDeliveryDining } from "react-icons/md";
import { AiOutlineDropbox } from "react-icons/ai";

const DeliveryType = ({ deliveryType }) => {
  const deliveryTypes = {
    collection: {
      label: "Collection",
      icon: <MdDeliveryDining />,
      color: "primary",
    },
    "drop-by": {
      label: "Drop at Impact",
      icon: <AiOutlineDropbox />,
      color: "secondary",
    },
  };

  return (
    <Chip
      label={deliveryTypes?.[deliveryType]?.label}
      color={deliveryTypes?.[deliveryType]?.color}
      variant="filled"
      sx={{
        p: 1,
        textTransform: "uppercase",
        fontWeight: "bold",
      }}
      icon={deliveryTypes?.[deliveryType]?.icon}
    />
  );
};
export default DeliveryType;
