import { Outlet } from "react-router-dom";
// components
import Header from "../Header/Header";

const RootLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};
export default RootLayout;
