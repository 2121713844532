import { v4 as uuid } from "uuid";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

// mui
import CircularProgress from "@mui/material/CircularProgress";

// redux
import {
  selectPackages,
  selectPackageType,
  selectBillingDetails,
  selectDeliveryOption,
  selectShippingDetails,
  selectCollectionDateTime,
} from "../../app/PersonalPackageSlice";
import { selectMerchantSessionKey } from "../../app/PaymentSlice";

// utils
import createElement from "../../utils/createElement";

// components
import ScreenLoader from "../ScreenLoader/ScreenLoader";
// import generateSCA from "../../utils/generateSCA";

const PaymentModal = ({
  showModal,
  isLoading,
  setModalClose,
  isGeneratingForm,
}) => {
  const useFormRef = useRef();

  const merchantSessionKey = useSelector(selectMerchantSessionKey);
  const billingDetailsRedux = useSelector(selectBillingDetails);
  const shippingDetailsRedux = useSelector(selectShippingDetails);
  const shippingPackageTypeRedux = useSelector(selectPackageType);
  const selectedDeliveryOptionRedux = useSelector(selectDeliveryOption);
  const deliveryPackageRedux = useSelector(selectPackages);
  const paymentAmount = Math.round(selectedDeliveryOptionRedux.price * 100);
  const collectionDate = useSelector(selectCollectionDateTime);
  const [loaderOpen, setLoaderOpen] = useState(false);

  const handleFormSubmit = async () => {
    // const customerAuth = await generateSCA();

    setLoaderOpen(true);

    const transactData = {
      transactionType: "Payment",
      vendorTxCode: uuid(),
      amount: paymentAmount,
      currency: "GBP",
      description: "Demo transaction",
      settlementReferenceText: "ABC1234",
      customerFirstName: billingDetailsRedux?.firstName,
      customerLastName: billingDetailsRedux?.lastName,
      billingAddress: {
        address1: billingDetailsRedux?.address1, //required
        city: billingDetailsRedux?.city, //required
        country: billingDetailsRedux?.country, //required
        postalCode: billingDetailsRedux?.postalCode, // not required for IE
        companyName: billingDetailsRedux?.companyName,
        phoneNo: billingDetailsRedux?.phoneNo,
        email: billingDetailsRedux?.email,
        // state: billingDetailsRedux?.state, //required only for US
      },
      entryMethod: "Ecommerce",
      apply3DSecure: "Disable",
      applyAvsCvcCheck: "UseMSPSetting",
      customerEmail: billingDetailsRedux?.email,
      customerPhone: billingDetailsRedux?.phoneNo, // TODO: format: + country-code number
      shippingDetails: {
        recipientFirstName: shippingDetailsRedux?.firstName,
        recipientLastName: shippingDetailsRedux?.lastName,
        shippingAddress1: shippingDetailsRedux?.address1,
        shippingCity: shippingDetailsRedux?.city,
        shippingPostalCode: shippingDetailsRedux?.postalCode,
        shippingCountry: shippingDetailsRedux?.country,
        // shippingState: shippingDetailsRedux?.state,
        companyName: shippingDetailsRedux?.companyName,
        phoneNo: shippingDetailsRedux?.phoneNo,
        email: shippingDetailsRedux?.email,
      },
      // recipientMobileNo: receiverDetailsRedux?.recipientMobileNo,
      // recipientEmail: receiverDetailsRedux?.recipientEmail,
      // referrerId: "",
      // customerMobilePhone: billingDetailsRedux?.mobileNo,
      // customerWorkPhone: billingDetailsRedux?.workPhoneNo,
      // strongCustomerAuthentication: customerAuth,
    };

    const merchantSessionKeyInput = createElement(
      "input",
      "merchantSessionKey",
      merchantSessionKey,
      "merchantSessionKey",
      true
    );

    const transactionObject = createElement(
      "input",
      "transactionObject",
      JSON.stringify(transactData),
      "transactionObject",
      true
    );

    const selectedShippingProviderObject = createElement(
      "input",
      "selectedShippingProviderObject",
      JSON.stringify(selectedDeliveryOptionRedux),
      "selectedShippingProviderObject",
      true
    );

    const deliveryPackageObject = createElement(
      "input",
      "deliveryPackageObject",
      JSON.stringify(deliveryPackageRedux),
      "deliveryPackageObject",
      true
    );

    const collectionDateTime = createElement(
      "input",
      "collectionDateTime",
      JSON.stringify(collectionDate),
      "collectionDateTime",
      true
    );

    const shippingPackageType = createElement(
      "input",
      "packageType",
      shippingPackageTypeRedux,
      "packageType",
      true
    );

    const formChildren = Array.from(useFormRef.current.children);

    formChildren.forEach((child) => {
      if (child?.nodeName === "INPUT") child.remove();
    });

    useFormRef.current.appendChild(merchantSessionKeyInput);
    useFormRef.current.appendChild(transactionObject);
    useFormRef.current.appendChild(selectedShippingProviderObject);
    useFormRef.current.appendChild(deliveryPackageObject);
    useFormRef.current.appendChild(collectionDateTime);
    useFormRef.current.appendChild(shippingPackageType);

    //useFormRef.current.submit();
  };

  return (
    <>
      <ScreenLoader open={loaderOpen} />
      <div
        style={{ display: `${showModal ? "block" : "none"}` }}
        id="alert-wrapper"
      >
        <div className="payment-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
            ref={useFormRef}
            action={`${process.env.REACT_APP_BASE_URL}/payment/transaction`}
            method="POST"
            className="card-details-wrapper"
          >
            <div
              style={{
                display: `${isLoading ? "block" : "none"}`,
                marginTop: "20px",
              }}
            >
              <CircularProgress />
            </div>

            <img
              style={{ display: `${isLoading ? "none" : "block"}` }}
              src="https://www.opayolabs.co.uk/OpayoDemo/images/cardPayments.png"
              alt=""
            />

            {/* iframe loads here */}
            <div id="sp-container"></div>

            <div
              className="buttons"
              style={{
                display: `${isLoading || isGeneratingForm ? "none" : "block"}`,
              }}
            >
              <div id="submit-container">
                <button type="submit">Make Payment</button>
                <button
                  onClick={setModalClose}
                  type="button"
                  className="cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
