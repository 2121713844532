const createElement = (
  elementType,
  elementName,
  elementValue,
  elementId,
  elementHidden = false
) => {
  const newInput = document.createElement(elementType);
  newInput.type = elementHidden ? "hidden" : "";
  newInput.name = elementName || "";
  newInput.value = elementValue || "";
  newInput.id = elementId || "";

  return newInput;
};

export default createElement;
