import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

// icons
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineDropbox } from "react-icons/ai";
import { MdDeliveryDining } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";

// redux
import {
  actionGoToNextPage,
  actionGoToPreviousPage,
  actionUpdateDeliveryOption,
  selectBillingDetails,
  selectDeliveryOption,
  selectQuotes,
  selectCollectionDateTime,
  actionUpdateSelectedCollectionDateTime,
  selectNonRestrictDateTimeDateTime,
  selectCollectionTime,
  actionUpdateSelectedCollectionTime,
} from "../../app/PersonalPackageSlice";

// utils
import { notify } from "../../utils/globalToast";

// styles
import "./DeliveryOptionsPersonal.scss";

const DeliveryOptionsPersonal = () => {
  const datePickerRef = useRef();
  const dispatch = useDispatch();
  const selectedDeliveryOptionRedux = useSelector(selectDeliveryOption);
  const selectedBillingDetails = useSelector(selectBillingDetails);
  const quotesRedux = useSelector(selectQuotes);

  const selectedCollectionDateTime = useSelector(selectCollectionDateTime);
  const selectedTime = useSelector(selectCollectionTime);
  const nonRestrictDateTime = useSelector(selectNonRestrictDateTimeDateTime);
  const [dateError, setDateError] = useState(undefined);
  const [timeError, setTimeError] = useState(undefined);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  const scrollToElement = () => {
    const { current } = datePickerRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNextStep = (quoteId) => {
    if (selectedTime === "") {
      setTimeError(true);
      scrollToElement();
      notify("error", "Preferred collection time is required");
      dispatch(actionUpdateDeliveryOption({}));
      return;
    }

    if (!dateError) {
      dispatch(
        actionUpdateDeliveryOption(
          quotesRedux?.filter((quote) => quote?.quoteId === quoteId)[0]
        )
      );
      dispatch(actionGoToNextPage());
    }
  };

  const handleDateTimeChange = (dateTime) => {
    const dateOnly = dayjs(dateTime).format("YYYY-MM-DD 00:00:00");
    dispatch(actionUpdateDeliveryOption({}));
    dispatch(
      actionUpdateSelectedCollectionDateTime(
        dayjs(dateOnly).add(selectedTime, "hour")
      )
    );
    setDateError(false);
    setTimeError(false);
  };

  const handleTimeChange = (event) => {
    dispatch(actionUpdateDeliveryOption({}));
    const selectedDateTime = selectedCollectionDateTime.add(
      event.target.value,
      "hour"
    );

    dispatch(actionUpdateSelectedCollectionDateTime(dayjs(selectedDateTime)));
    dispatch(actionUpdateSelectedCollectionTime(event.target.value));
    setTimeError(false);
  };

  return (
    <>
      <Stack
        padding="10px"
        marginBottom="30px"
        marginTop="30px"
        gap="30px"
        direction="column"
        ref={datePickerRef}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2 }}
          justifyContent="space-between"
          alignItems={{ xs: "stretch", sm: "center" }}
        >
          <Button
            startIcon={<IoIosArrowBack />}
            variant="contained"
            color="warning"
            onClick={() => dispatch(actionGoToPreviousPage())}
          >
            Change Package Data
          </Button>
        </Stack>

        {/* collection date section start */}

        <Stack width="100%" gap={1}>
          <Typography variant="h5" fontWeight={500} gutterBottom>
            Choose your shipment / collection date & time
          </Typography>
          <Stack direction={{ sm: "row" }} gap={3} marginBottom={"30px"}>
            <Stack
              direction={"row"}
              gap={2}
              width={"100%"}
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Shipment / Collection Date"
                  slotProps={{
                    textField: {
                      error: !!dateError,
                      helperText: (
                        <>
                          {dateError && <span>{dateError}</span>}
                          <span
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: isSmallScreen
                                ? "flex-start"
                                : isMediumScreen
                                ? "flex-start"
                                : "center",
                            }}
                          >
                            <RiErrorWarningFill
                              size={isSmallScreen ? 12 : 15}
                              style={{
                                position: "relative",
                                top: isSmallScreen ? "1px" : "1px",
                              }}
                            />
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: isSmallScreen ? "10px" : "12px",
                                position: "relative",
                                top: isSmallScreen
                                  ? "0px"
                                  : isMediumScreen
                                  ? "0px"
                                  : "1px",
                              }}
                            >
                              Shipment date can't be more than 10 days in the
                              future.
                            </span>
                          </span>
                        </>
                      ),
                    },
                  }}
                  onError={(err) => {
                    if (err === "invalidDate")
                      setDateError("Collection Date is required");
                  }}
                  value={dayjs(selectedCollectionDateTime)}
                  sx={{ width: { xs: "100%", sm: "50%" } }}
                  onChange={handleDateTimeChange}
                  disablePast
                  minDate={dayjs(nonRestrictDateTime)}
                  maxDate={dayjs(nonRestrictDateTime).add(9, "day")}
                  shouldDisableDate={(date) =>
                    [0, 6].includes(dayjs(date).day())
                  }
                />
              </LocalizationProvider>
              <FormControl
                sx={{ width: { xs: "100%", sm: "50%" } }}
                error={timeError}
              >
                <InputLabel id="demo-simple-select-label">
                  Collection Time
                </InputLabel>
                <Select
                  labelId="collection-time-label"
                  id="collection-time-select"
                  value={selectedTime}
                  label="Preferred Time"
                  onChange={handleTimeChange}
                >
                  <MenuItem value={14.5}>12 pm - 5 pm</MenuItem>
                </Select>
                {timeError && (
                  <FormHelperText>Preferred Time is required</FormHelperText>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </Stack>

        {/* collection date section end */}

        <Stack width="100%" gap={1}>
          <Typography variant="h5" fontWeight={500} gutterBottom>
            Choose your preferred courier service
          </Typography>
          <Typography variant="h6">
            <b>{quotesRedux?.length}</b> Available Courier Services
          </Typography>

          <Grid container spacing="20px">
            {quotesRedux?.map((quote) => {
              return (
                <Grid key={quote?.quoteId} item xs={12} sm={6} md={6} lg={4}>
                  <Card sx={{ width: "100%" }}>
                    <CardContent>
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        gap="20px"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="20px"
                          height="50px"
                        >
                          <img
                            style={{ objectFit: "cover" }}
                            className="quote-logo"
                            src={`${
                              quote?.providerCompany === "dhl"
                                ? "/DHL-Logo.png"
                                : quote?.providerCompany === "indiapost"
                                ? "/indiapost.png"
                                : quote?.providerCompany === "ups"
                                ? "/ups.png"
                                : quote?.providerCompany === "evri" ||
                                  quote?.providerCompany === "dhl_evri"
                                ? "/evri-logo.png"
                                : ""
                            }`}
                            alt="courier provider logo"
                          />
                          <Typography variant="h6">
                            {quote?.providerName}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={2}
                          className="parcel-shop-btn-container"
                          justifyContent={{ xs: "center" }}
                        >
                          {" "}
                          {quote?.deliveryType === "collection" && (
                            <Chip
                              label="Collection"
                              color="primary"
                              variant="filled"
                              sx={{
                                p: 1,
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              }}
                              icon={<MdDeliveryDining />}
                            />
                          )}{" "}
                          {quote?.deliveryType === "drop-by" && (
                            <Chip
                              label="Drop at Impact"
                              color="secondary"
                              variant="filled"
                              sx={{
                                p: 1,
                                textTransform: "uppercase",
                                fontWeight: "bold",
                              }}
                              icon={<AiOutlineDropbox />}
                            />
                          )}{" "}
                          {quote?.providerCompany === "dhl" &&
                          quote?.deliveryType === "drop-by" ? (
                            <a
                              href={`https://locator.dhl.com/results?countryCode=${selectedBillingDetails.country}&address=${selectedBillingDetails.postalCode}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                variant="outlined"
                                color="warning"
                                size="small"
                              >
                                DHL Service Points
                              </Button>
                            </a>
                          ) : (quote?.providerCompany === "evri" ||
                              quote?.providerCompany === "dhl_evri") &&
                            quote?.deliveryType === "drop-by" ? (
                            <a
                              href={`https://www.evri.com/find-a-parcelshop?search=${selectedBillingDetails.postalCode}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button variant="outlined" size="small">
                                EVRi ParcelShops
                              </Button>
                            </a>
                          ) : null}
                        </Stack>

                        <div>
                          <Typography variant="subtitle1">
                            Delivery within{" "}
                            <b>
                              {quote?.providerCompany === "dhl"
                                ? quote?.timeTaken === "1" ||
                                  quote?.timeTaken === 1
                                  ? "next day"
                                  : `${quote?.timeTaken} days`
                                : quote?.providerCompany === "dhl_evri"
                                ? quote?.timeTaken === "1" ||
                                  quote?.timeTaken === 1
                                  ? "next day"
                                  : `${quote?.timeTaken} days`
                                : quote?.providerCompany === "ups"
                                ? "2-3 days"
                                : "2-3 days"}
                            </b>
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            £ {quote?.price}
                          </Typography>
                        </div>

                        <Button
                          fullWidth
                          variant="contained"
                          color={
                            selectedDeliveryOptionRedux?.quoteId ===
                            quote?.quoteId
                              ? "success"
                              : "info"
                          }
                          onClick={() => handleNextStep(quote?.quoteId)}
                        >
                          {selectedDeliveryOptionRedux?.quoteId ===
                          quote?.quoteId
                            ? "SELECTED"
                            : "SELECT"}
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
export default DeliveryOptionsPersonal;
