export const MAX_LENGTH = 100;
export const DEFAULT_COUNTRY = "GB";
export const ADMIN_LOGIN_COOKIE = "admin-pass-key";

export const routes = {
  homePage: "/",
  queryPage: "/query",
  thankyouPage: "/thankyou",
  getOrderInfoPage: "/get-order-info",
  adminLoginPage: "/auth/admin-login",
  adminRoutesRootRoute: "/admin",
};

export const serverRoutes = {
  adminLogin: "/auth/admin",
};

export const DEFAULT_LENGTH_LIMIT = "lessThan100";

export const lengthLimits = [
  { label: "Less than 100 cm", value: DEFAULT_LENGTH_LIMIT },
  { label: "More than 100 cm", value: "moreThan100" },
];
