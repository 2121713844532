// TODO: update to mui
import { Link } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";

// mui
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";

// hooks
import useAuth from "../../hooks/useAuth";

// utils
import { routes } from "../../utils/constants";

// styles
import "./Header.scss";

const Header = () => {
  const { adminLogout, isAdmin, isAuthenticated } = useAuth();
  const isXSScreen = useMediaQuery("(max-width:360px)");

  return (
    <header>
      <div className="top-header">
        <Container fluid>
          <div className="top-header__right-text">
            <img src="/dhl-business-small.png" alt="" />
            <Typography fontSize={isXSScreen ? "13px" : "16px"}>
              Authorized Service partner
            </Typography>
          </div>
        </Container>
      </div>
      <div className="primary-header">
        <Container>
          <Navbar expand="xl">
            <Navbar.Brand
              as={Link}
              to="/"
              style={{
                ...(isXSScreen && { marginRight: "16px" }),
              }}
            >
              <img
                style={{
                  ...(isXSScreen && { width: "165px", marginLeft: "8px" }),
                }}
                src="/navbar-logo.png"
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle
              style={{ ...(isXSScreen && { marginRight: 0 }) }}
              aria-controls="basic-navbar-nav"
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="ml-auto">
                <Nav.Link as={Link} to={routes.getOrderInfoPage}>
                  GET ORDER INFO
                </Nav.Link>
                {isAuthenticated && isAdmin && (
                  <Nav.Link as={Link} to={routes.adminRoutesRootRoute}>
                    UPDATE BOOKING
                  </Nav.Link>
                )}
              </Nav>
              <div id="basic-navbar-nav-buttons">
                <Button
                  href="https://impactexpress.co.uk/"
                  variant="outline-warning text-black"
                >
                  GO TO HOMEPAGE
                </Button>
                {isAuthenticated && isAdmin && (
                  <Button onClick={adminLogout} variant="warning">
                    LOG OUT
                  </Button>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </header>
  );
};

export default Header;
