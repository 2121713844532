import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
  actionJumpToPage,
  selectCurrentPage,
} from "../../app/PersonalPackageSlice";

// components
import DeliverySummary from "../../components/DeliverySummary/DeliverySummary";
import QuotesPageContainer from "../../components/QuotesPageContainer/QuotesPageContainer";
import AddressDetailsPersonal from "../../components/AddressDetailsPersonal/AddressDetailsPersonal";
import PackageDetailsPersonal from "../../components/PackageDetailsPersonal/PackageDetailsPersonal";
import DeliveryOptionsPersonal from "../../components/DeliveryOptionsPersonal/DeliveryOptionsPersonal";

const HomePage = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectCurrentPage);

  useEffect(() => {
    dispatch(actionJumpToPage(0));
  }, [dispatch]);

  const formsToShow = [
    <PackageDetailsPersonal />,
    <DeliveryOptionsPersonal />,
    <AddressDetailsPersonal />,
    <DeliverySummary />,
  ];

  const navigationList = [
    { name: "Package Details" },
    { name: "Price & Delivery Options" },
    { name: "Address Details " },
    { name: "Delivery Summary" },
  ];

  return (
    <QuotesPageContainer
      currentStep={currentPage}
      title="Parcel"
      navigationList={navigationList}
    >
      {formsToShow[currentPage]}
    </QuotesPageContainer>
  );
};
export default HomePage;
