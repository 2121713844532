import Typography from "@mui/material/Typography";

const DeliveryWithin = ({ providerCompany, timeTaken }) => {
  let deliveryTime = "2-3 days"; // Default delivery time

  if (providerCompany === "dhl" || providerCompany === "dhl_evri") {
    if (timeTaken === "1" || timeTaken === 1) {
      deliveryTime = "next day";
    } else {
      deliveryTime = `${timeTaken} days`;
    }
  } else if (providerCompany === "ups") {
    deliveryTime = "2-3 days";
  }

  return (
    <Typography fontWeight="bold" variant="subtitle1">
      Delivery within {deliveryTime}
    </Typography>
  );
};

export default DeliveryWithin;
