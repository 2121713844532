import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// utils
import { getCountryNameFromCode } from "../../utils/getPlaces";

const BillingDetails = ({ billingErrors, billingValues, handleChange }) => {
  return (
    <Stack width="100%" direction="column" gap="10px">
      <Typography variant="h4" gutterBottom>
        Sender / Collection Details
      </Typography>

      <Grid container columnSpacing={1} rowSpacing={1.5}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.firstName}
            id="billing.firstName"
            label="First Name"
            helperText={billingErrors?.firstName}
            value={billingValues?.firstName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.lastName}
            id="billing.lastName"
            label="Last Name"
            helperText={billingErrors?.lastName}
            value={billingValues?.lastName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.email}
            id="billing.email"
            label="Email"
            helperText={billingErrors?.email}
            value={billingValues?.email}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.phoneNo}
            id="billing.phoneNo"
            label="Phone"
            helperText={billingErrors?.phoneNo}
            value={billingValues?.phoneNo}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!!billingErrors?.companyName}
            id="billing.companyName"
            label="Company Name"
            helperText={billingErrors?.companyName}
            value={billingValues?.companyName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!!billingErrors?.address1}
            id="billing.address1"
            label="Address1"
            fullWidth
            helperText={billingErrors?.address1}
            value={billingValues?.address1}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.city}
            id="billing.city"
            label="City"
            helperText={billingErrors?.city}
            value={billingValues?.city}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.state}
            id="billing.state"
            label="State"
            helperText={billingErrors?.state}
            value={billingValues?.state}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.postalCode}
            id="billing.postalCode"
            label="Postal Code"
            helperText={billingErrors?.postalCode}
            value={billingValues?.postalCode}
            onChange={handleChange}
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!billingErrors?.country}
            id="billing.country"
            label="Country"
            helperText={billingErrors?.country}
            value={getCountryNameFromCode(billingValues?.country)}
            onChange={handleChange}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default BillingDetails;
