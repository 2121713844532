import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUpdateSelectedCollectionDateTime,
  actionUpdateSelectedCollectionTime,
  selectCollectionDateTime,
  selectCollectionTime,
  selectNonRestrictDateTimeDateTime,
} from "../../app/PersonalPackageSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 275, sm: 400 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PopUpModal({ isOpen, handleClose, as, ...restProps }) {
  const dispatch = useDispatch();
  const [dateError, setDateError] = React.useState(undefined);
  // const [timeError, setTimeError] = React.useState(undefined);
  const selectedCollectionDateTime = useSelector(selectCollectionDateTime);
  const selectedTime = useSelector(selectCollectionTime);
  const nonRestrictDateTime = useSelector(selectNonRestrictDateTimeDateTime);

  const handleDateTimeChange = (dateTime) => {
    dispatch(
      actionUpdateSelectedCollectionDateTime(dayjs(dateTime).toISOString())
    );
    setDateError(false);
    restProps.setTimeError(false);
  };

  const handleNewCollectionDateSelection = () => {
    if (selectedTime === "") {
      restProps.setTimeError(true);
      return;
    }

    restProps.setIsNewCollectionDateSelected(true);
    handleClose();
  };

  const handleTimeChange = (event) => {
    const selectedDateTime = dayjs(selectedCollectionDateTime).add(
      event.target.value,
      "hour"
    );
    dispatch(actionUpdateSelectedCollectionDateTime(dayjs(selectedDateTime)));
    dispatch(actionUpdateSelectedCollectionTime(event.target.value));
    restProps.setTimeError(false);
  };

  return (
    <div>
      {as === "TRACK-ORDER" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpen}>
            <Box sx={style}>
              {/* <Typography id="transition-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography> */}
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, fontWeight: "600" }}
                textAlign={"center"}
              >
                Before Tracking Your Order, Please Generate Your Shipment Label.
              </Typography>
              <Stack
                marginTop={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  sx={{
                    width: "30%",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  close
                </Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>
      )}

      {as === "GENERATE-LABEL" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpen}>
            <Box sx={style}>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, fontWeight: "600" }}
                textAlign={"center"}
                color={"error"}
              >
                Oops! It seems the collection date and time for your shipment
                label have expired.
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, fontWeight: "600" }}
                fontSize={14}
              >
                Please select another shipment/collection date & time:
              </Typography>
              <Stack direction={{ sm: "row" }} gap={3} marginY={"15px"}>
                <Stack
                  direction={"row"}
                  gap={2}
                  width={"100%"}
                  flexWrap={{ xs: "wrap", sm: "nowrap" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Shipment / Collection Date"
                      slotProps={{
                        textField: {
                          error: !!dateError,
                          helperText: dateError,
                        },
                      }}
                      onError={(err) => {
                        if (err === "invalidDate")
                          setDateError("Collection Date is required");
                      }}
                      value={dayjs(selectedCollectionDateTime)}
                      sx={{ width: { xs: "100%", sm: "50%" } }}
                      onChange={handleDateTimeChange}
                      disablePast
                      minDate={dayjs(nonRestrictDateTime)}
                      maxDate={dayjs(nonRestrictDateTime).add(9, "day")}
                      shouldDisableDate={(date) =>
                        [0, 6].includes(dayjs(date).day())
                      }
                    />
                  </LocalizationProvider>
                  <FormControl
                    sx={{ width: { xs: "100%", sm: "50%" } }}
                    error={restProps.timeError}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Collection Time
                    </InputLabel>
                    <Select
                      labelId="collection-time-label"
                      id="collection-time-select"
                      value={selectedTime}
                      label=" Collection Time"
                      onChange={handleTimeChange}
                    >
                      <MenuItem value={14.5}>12 pm - 5 pm</MenuItem>
                      {/* <MenuItem value={12}>11 am - 1 pm</MenuItem> */}
                      {/* <MenuItem value={14}>1pm - 3 pm</MenuItem> */}
                    </Select>
                    {restProps.timeError && (
                      <FormHelperText>
                        Collection Time is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack
                marginTop={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  sx={{
                    width: "30%",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleNewCollectionDateSelection}
                >
                  Select
                </Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>
      )}

      {as === "BACKEND-ERROR" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isOpen}>
            <Box sx={style}>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, fontWeight: "600" }}
                textAlign={"center"}
                color={"error"}
              >
                We're experiencing technical difficulties right now. Please try
                again later.
              </Typography>
              <Stack
                marginTop={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  sx={{
                    width: "30%",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  close
                </Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
