// mui
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// utils
import { getCountryNameFromCode } from "../../utils/getPlaces";

export const SummaryCardField = ({ fieldKey, fieldValue }) => {
  return (
    <Typography variant="subtitle2">
      <span style={{ fontWeight: "bold", marginRight: "5px" }}>
        {" "}
        {fieldKey}:
      </span>
      {fieldValue}
    </Typography>
  );
};

const SummaryCard = ({ details, title, type }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1">
          {details?.firstName} &nbsp;
          {details?.lastName},
        </Typography>
        <Typography variant="subtitle1">{details?.address1}</Typography>
        <SummaryCardField fieldKey="Email" fieldValue={details?.email} />
        <SummaryCardField
          fieldKey="Company Name"
          fieldValue={details?.companyName}
        />
        <SummaryCardField fieldKey="City" fieldValue={details?.city} />
        <SummaryCardField
          fieldKey="Postal Code"
          fieldValue={details?.postalCode}
        />
        <SummaryCardField fieldKey="State" fieldValue={details?.state} />
        <SummaryCardField
          fieldKey="Country"
          fieldValue={getCountryNameFromCode(details?.country)}
        />

        {/* {type === "billing" && <SummaryCardField fieldKey="Collection Date" >} */}
        {/* {type === "shipping" && <SummaryCardField fieldKey="Delivery Date" >} */}
      </CardContent>
    </Card>
  );
};
export default SummaryCard;
