import * as Yup from "yup";

const billingValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name required").max(20),
  lastName: Yup.string().required("Last Name required").max(20),
  address1: Yup.string().required("Required").max(50),
  city: Yup.string().required("Required").max(50),
  state: Yup.string(), // required if shippingCountry is US
  country: Yup.string().required("Required").length(2),
  postalCode: Yup.string().max(10), // Not required when shippingCountry is IE
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email required")
    .max(80),
  phoneNo: Yup.string().required("Required").max(19),
  companyName: Yup.string()
    .required("Add your name if company name doesn't exists")
    .max(40),
});

export default billingValidationSchema;
