import axios from "axios";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// mui
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

// icons
import { HiArrowSmRight, HiCheckCircle, HiXCircle } from "react-icons/hi";

import {
  selectCollectionDateTime,
  selectLabelGenerateStatus,
  actionUpdateLabelGenerateStatus,
} from "../../app/PersonalPackageSlice";

// components
import OrderInfoPackage from "./OrderInfoPackage";
import PopUpModal from "../../components/PopUpModal/PopUpModal";
import ProviderDetails from "./ProviderDetails/ProviderDetails";
import PackageDetails from "./AdminPackageDetails/PackageDetails";

// utils
import { notify } from "../../utils/globalToast";
import { DEFAULT_COUNTRY } from "../../utils/constants";
import { getCountryNameFromCode } from "../../utils/getPlaces";
import getFormattedDateTime from "../../utils/getFormattedDateTime";

const OrderInfo = ({ isAdminPage }) => {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();
  const orderIdFromParams = queryParameters.get("order_id");

  const [orderInfo, setOrderInfo] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeError, setTimeError] = useState(undefined);
  const [fieldError, setFieldError] = useState(undefined);
  const [orderId, setOrderId] = useState(orderIdFromParams || "");
  const [adminInfo, setAdminInfo] = useState({
    priceComparision: false,
    showUpdateButton: false,
  });
  const [popUpModalContent, setPopUpModalContent] = useState(undefined);
  const [isNewCollectionDateSelected, setIsNewCollectionDateSelected] =
    useState(false);

  const selectedLabelGenerateStatus = useSelector(selectLabelGenerateStatus);
  const selectedCollectionDateTime = useSelector(selectCollectionDateTime);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const chipColor = orderInfo?.status === 1 ? "success" : "error";
  const chipLabel = orderInfo?.status === 1 ? "Successful" : "Unsuccessful";
  const chipIcon = orderInfo?.status === 1 ? <HiCheckCircle /> : <HiXCircle />;

  const orderInfoPackages = orderInfo?.packages;
  const orderInfoBillingAddress = orderInfo?.billing_address;
  const orderInfoShippingAddress = orderInfo?.shipping_address;
  const orderInfoShippingProvider = orderInfo?.shipping_provider;
  const updatedOrderInfoShippingProvider = orderInfo?.updated_shipping_provider;
  const isUKToUKDelivery =
    orderInfoBillingAddress === DEFAULT_COUNTRY &&
    orderInfoShippingAddress === DEFAULT_COUNTRY;

  useEffect(() => {
    if (orderIdFromParams) {
      getOrderInfo();
    }
  }, []);

  const getOrderInfo = async () => {
    if (!orderId) {
      setFieldError(true);
      setOrderInfo("");
    }
    if (!fieldError && orderId) {
      try {
        setAdminInfo({ priceComparision: false, showUpdateButton: false });
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/booking/details/${orderId}`
        );

        const orderedInfo = response.data.data;

        if (orderedInfo?.shipment_info !== null) {
          dispatch(actionUpdateLabelGenerateStatus(true));
        } else {
          dispatch(actionUpdateLabelGenerateStatus(false));
        }
        setOrderInfo(orderedInfo);
      } catch (error) {
        setPopUpModalContent("BACKEND-ERROR");
        setPopUpOpen(true);
        console.log("error-while-get-order-info", error);
      }
    }
  };

  const handleOrderIdChange = (e) => {
    const value = e.target.value;
    setOrderId(value);

    if (!value) {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  };

  const handleViewLabel = async () => {
    if (!orderInfo?.shipment_info) return;

    if (orderInfo?.shipping_provider.providerCompany === "dhl") {
      window.open(
        orderInfo?.shipment_info?.documents.find(
          (item) => item.type === "label"
        )?.value?.uri,
        "_blank"
      );
    }

    if (orderInfo?.shipping_provider.providerCompany === "ups") {
      window.open(
        orderInfo?.shipment_info?.PackageResults?.ShippingLabel?.value?.uri,
        "_blank"
      );
    }
  };

  const handleGenerateLabel = async () => {
    setPopUpModalContent(undefined);

    const currentDateTime = dayjs();
    const collectionDateTime = dayjs(orderInfo?.collection_date_time);

    const isSameDate =
      currentDateTime.format("YYYY-MM-DD") ===
      collectionDateTime.format("YYYY-MM-DD");

    if (isSameDate && !isNewCollectionDateSelected) {
      setPopUpModalContent("GENERATE-LABEL");
      setPopUpOpen(true);
      return;
    }

    if (collectionDateTime < currentDateTime && !isNewCollectionDateSelected) {
      setPopUpModalContent("GENERATE-LABEL");
      setPopUpOpen(true);
      return;
    }

    if (isNewCollectionDateSelected) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/shipping/label/generate`,
          {
            booking_id: orderInfo.uuid,
            collection_date_time: dayjs(selectedCollectionDateTime),
          }
        );

        if (response) {
          setIsLoading(false);
          dispatch(actionUpdateLabelGenerateStatus(true));
          getOrderInfo();
          notify("success", "label generated");
        }
      } catch (error) {
        setIsLoading(false);
        setPopUpModalContent("BACKEND-ERROR");
        setPopUpOpen(true);
        console.log(
          "error-while-gen-label-with-outdated-collection-date-time",
          error
        );
      }
    }

    if (collectionDateTime > currentDateTime && !isNewCollectionDateSelected) {
      setIsNewCollectionDateSelected(false);
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/shipping/label/generate`,
          {
            booking_id: orderInfo.uuid,
          }
        );

        if (response) {
          setIsLoading(false);
          dispatch(actionUpdateLabelGenerateStatus(true));
          getOrderInfo();
          notify("success", "label generated");
        }
      } catch (error) {
        setIsLoading(false);
        setPopUpModalContent("BACKEND-ERROR");
        setPopUpOpen(true);
        console.log("error-while-gen-label", error);
      }
    }
  };

  const handleTrackOrder = () => {
    setPopUpModalContent("TRACK-ORDER");

    let trackUrl = false;

    if (
      orderInfo?.shipping_provider.providerCompany === "dhl" &&
      orderInfo?.shipment_info
    ) {
      trackUrl = `https://www.dhl.com/gb-en/home/tracking.html?tracking-id=${orderInfo.shipment_info?.shipmentTrackingNumber}`;
    }

    if (
      orderInfo?.shipping_provider.providerCompany === "ups" &&
      orderInfo?.shipment_info
    ) {
      trackUrl = `https://www.ups.com/track?tracknum=${orderInfo.shipment_info?.ShipmentIdentificationNumber}`;
    }

    trackUrl ? window.open(trackUrl, "_blank") : setPopUpOpen(true);
  };

  const renderGridRow = (label, value) => {
    return (
      value && (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">{label}:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              {value}
            </Typography>
          </Grid>
        </Grid>
      )
    );
  };

  const handlePopUpClose = () => {
    setPopUpOpen(false);
    setTimeError(false);
  };

  const handleAdminInfo = (value) => {
    setAdminInfo((prev) => ({ ...prev, ...value }));
  };

  return (
    <>
      <PopUpModal
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        as={popUpModalContent}
        setIsNewCollectionDateSelected={setIsNewCollectionDateSelected}
        timeError={timeError}
        setTimeError={setTimeError}
        collectionDateTime={orderInfo?.collection_date_time}
      />

      <Container>
        <Stack sx={{ margin: "30px 0 40px 0" }} gap="16px">
          <Typography
            fontWeight="bold"
            variant={isSmallScreen ? "h5" : "h4"}
            gutterBottom
          >
            Get Order Details
          </Typography>

          <TextField
            fullWidth
            error={!!fieldError}
            id="orderId"
            label="Order ID"
            helperText={fieldError && "Required"}
            value={orderId}
            onChange={handleOrderIdChange}
            disabled={orderIdFromParams}
          />

          {!orderIdFromParams && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<HiArrowSmRight />}
              onClick={getOrderInfo}
              sx={{ maxWidth: "100px" }}
            >
              GO
            </Button>
          )}
        </Stack>

        {orderInfo && (
          <Stack rowGap={3}>
            <div>
              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                fontWeight="bold"
              >
                Order #{orderInfo?.uuid}
              </Typography>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                columnGap={2}
              >
                <Chip
                  label={chipLabel}
                  color={chipColor}
                  icon={chipIcon}
                  variant="outlined"
                  sx={{ fontWeight: "bold" }}
                />
                <Typography
                  gutterBottom
                  variant={isSmallScreen ? "subtitle2" : "subtitle1"}
                  sx={{ mt: 1 }}
                >
                  Created at{" "}
                  <b>{getFormattedDateTime(orderInfo?.created_at)}</b>
                </Typography>
              </Stack>
            </div>

            <Stack gap={2}>
              <div>
                <Typography variant={isSmallScreen ? "h5" : "h4"}>
                  Collection Date & Time :
                </Typography>
                <Typography
                  fontWeight="bold"
                  variant={isSmallScreen ? "subtitle2" : "subtitle1"}
                >
                  {dayjs(orderInfo?.collection_date_time).format(
                    "ddd MMM D YYYY h:mm:ss A"
                  )}
                </Typography>
              </div>

              <Grid container spacing={1}>
                {selectedLabelGenerateStatus &&
                  orderInfoShippingProvider?.providerCompany !== "dhl_evri" && (
                    <Grid xs={12} sm={6} md={3} lg={2.4} item>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          fontWeight: isLoading ? "600" : "",
                        }}
                        // href={orderInfo?.shipment_info?.uri}
                        loading={isLoading}
                        loadingIndicator={
                          <CircularProgress sx={{ color: "grey" }} size={16} />
                        }
                        endIcon={null}
                        loadingPosition="end"
                        onClick={handleViewLabel}
                      >
                        VIEW LABEL
                      </LoadingButton>
                    </Grid>
                  )}

                {!selectedLabelGenerateStatus &&
                  orderInfoShippingProvider?.providerCompany !== "dhl_evri" && (
                    <Grid xs={12} sm={6} md={3} lg={2.4} item>
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                          fontWeight: isLoading ? "600" : "",
                        }}
                        loading={isLoading}
                        loadingIndicator={
                          <CircularProgress sx={{ color: "grey" }} size={16} />
                        }
                        endIcon={null}
                        loadingPosition="end"
                        onClick={handleGenerateLabel}
                      >
                        {isLoading ? "GENERATING" : "GENERATE LABEL"}
                      </LoadingButton>
                    </Grid>
                  )}

                {orderInfoShippingProvider?.providerCompany !== "dhl_evri" && (
                  <Grid xs={12} sm={6} md={3} lg={2.4} item>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleTrackOrder}
                    >
                      TRACK ORDER
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Stack>

            <Stack gap={2}>
              <Typography variant={isSmallScreen ? "h5" : "h4"}>
                Package Information :
              </Typography>

              {isAdminPage ? (
                <PackageDetails
                  orderId={orderId}
                  isUKToUKDelivery={isUKToUKDelivery}
                  packageDetails={{ packages: orderInfoPackages }}
                  orderInfoShippingProvider={orderInfoShippingProvider}
                  handleAdminInfo={handleAdminInfo}
                  adminInfo={adminInfo}
                  handleUpdateBookingDetailsData={(data) => {
                    setOrderInfo(data);
                    setAdminInfo({
                      priceComparision: false,
                      showUpdateButton: false,
                    });
                  }}
                />
              ) : (
                orderInfoPackages?.map((item, index) => (
                  <OrderInfoPackage package={item} index={index} key={index} />
                ))
              )}
            </Stack>

            <ProviderDetails
              isSmallScreen={isSmallScreen}
              orderInfoShippingProvider={orderInfoShippingProvider}
              updatedOrderInfoShippingProvider={
                updatedOrderInfoShippingProvider
              }
            />

            <Stack spacing={2}>
              <Typography
                variant={isSmallScreen ? "h5" : "h4"}
                gutterBottom={true}
              >
                Customer Information :
              </Typography>

              <Stack direction="column" gap="12px">
                {renderGridRow(
                  "Name",
                  `${orderInfo?.first_name} ${orderInfo?.last_name}`
                )}
                {renderGridRow("Email", orderInfo?.email)}
                {renderGridRow("Phone", orderInfo?.phone)}
                {renderGridRow(
                  "Shipping Address",
                  <Stack>
                    <Typography fontWeight="bold" variant="subtitle1">
                      {orderInfoShippingAddress?.recipientFirstName}{" "}
                      {orderInfoShippingAddress?.recipientLastName}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoShippingAddress?.shippingAddress1}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoShippingAddress?.shippingAddress2}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoShippingAddress?.shippingAddress3}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoShippingAddress?.shippingCity},{" "}
                      {getCountryNameFromCode(
                        orderInfoShippingAddress?.shippingCountry
                      )}{" "}
                      - {orderInfoShippingAddress?.shippingPostalCode}
                    </Typography>
                  </Stack>
                )}
                {renderGridRow(
                  "Billing Address",
                  <Stack>
                    <Typography variant="subtitle1">
                      {orderInfoBillingAddress?.address1}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoBillingAddress?.address2}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoBillingAddress?.address3}
                    </Typography>
                    <Typography variant="subtitle1">
                      {orderInfoBillingAddress?.city},{" "}
                      {getCountryNameFromCode(orderInfoBillingAddress?.country)}
                      - {orderInfoBillingAddress?.postalCode}
                    </Typography>
                  </Stack>
                )}
                {renderGridRow("Transaction ID", orderInfo?.transaction_id)}
              </Stack>
            </Stack>
          </Stack>
        )}

        {orderInfo === null && (
          <Stack sx={{ my: 8 }}>
            <Typography variant="h5" fontWeight="bold">
              Sorry No Orders Were Found.
            </Typography>
          </Stack>
        )}
      </Container>
    </>
  );
};

OrderInfo.propTypes = {
  isAdminPage: PropTypes.bool,
};

OrderInfo.defaultProps = {
  isAdminPage: false,
};

export default OrderInfo;
