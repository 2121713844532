import { Route, BrowserRouter, Routes } from "react-router-dom";

// layout
import RootLayout from "./components/RootLayout/RootLayout";

// (un)protected routes
import AdminLoggedRoutes from "./components/ProtectedRoutes/AdminLoggedRoutes";
import AdminNonLoggedRoutes from "./components/ProtectedRoutes/AdminNonLoggedRoutes";

// pages
import HomePage from "./pages/HomePage/HomePage";
import QueryPage from "./pages/QueryPage/QueryPage";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";

// components
import OrderInfo from "./components/OrderInfo/OrderInfo";

// utils
import { routes } from "./utils/constants";

// styles
import "./App.scss";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.homePage} element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path={routes.queryPage} element={<QueryPage />} />
          <Route path={routes.thankyouPage} element={<ThankYouPage />} />
          <Route path={routes.getOrderInfoPage} element={<OrderInfo />} />

          <Route
            path={routes.adminRoutesRootRoute}
            element={<AdminLoggedRoutes />}
          >
            <Route index element={<OrderInfo isAdminPage={true} />} />
          </Route>
        </Route>

        <Route element={<AdminNonLoggedRoutes />}>
          <Route path={routes.adminLoginPage} element={<AdminLoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
