import { configureStore } from "@reduxjs/toolkit";

// reducers
import PaymentSlice from "./PaymentSlice";
import PersonalPackageSlice from "./PersonalPackageSlice";

export const store = configureStore({
  reducer: {
    paymentSlice: PaymentSlice,
    personalPackage: PersonalPackageSlice,
  },
});
