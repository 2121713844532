import { useEffect, useState } from "react";

// mui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import ToggleButton from "@mui/material/ToggleButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// components
import FormHelper from "./formHelper";
import PopUpModal from "../PopUpModal/PopUpModal";
import ScreenLoader from "../ScreenLoader/ScreenLoader";
import DropdownWithSearch from "../DropdownWithSearch/DropdownWithSearch";
import PackageTextFields from "../PackageDetailsUpdate/PackageTextFields";

// utils
import { getCountries } from "../../utils/getPlaces";
import { DEFAULT_COUNTRY } from "../../utils/constants";

// hooks
import useResponsive from "../../hooks/useResponsive";

// styles
import "./PackageDetailsPersonal.scss";

const TC = () => {
  return (
    <Typography>
      I agree to{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://impactexpress.co.uk/services/terms-and-conditions/"
      >
        Terms and Conditions
      </a>
    </Typography>
  );
};

const PackageDetailsPersonal = () => {
  const isMobile = useResponsive("down", "sm");

  const {
    handleSubmit,
    handlePostalCodeChange,
    handleLocationChange,
    changeAgreeToTC,
    handlePackageTypeChange,
    addNewPackage,
    handlePackageChange,
    removePackage,
    errors,
    values,
    loaderOpen,
    popUpOpen,
    setPopUpOpen,
  } = FormHelper();

  const [allCountries, setAllCountries] = useState([]);
  const [fromCountryLabel, setFromCountryLabel] = useState("");
  const [toCountryLabel, setToCountryLabel] = useState("");

  useEffect(() => {
    const countries = getCountries();
    setAllCountries(countries);
  }, []);

  const handlePopUpClose = () => setPopUpOpen(false);

  return (
    <>
      <ScreenLoader open={loaderOpen} />
      <PopUpModal
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        as={"BACKEND-ERROR"}
      />
      <form
        onSubmit={handleSubmit}
        style={{
          padding: "10px",
          marginTop: "30px",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "20px",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          fontWeight="500"
          fontSize={isMobile ? "30px" : "36px"}
        >
          Location Details
        </Typography>

        <Grid container spacing={{ sm: 3, xs: 2 }}>
          {/* Billing Country and Postal Code */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={{ xs: 2, sm: 1 }}>
              <Grid item xs={12} sm={8}>
                <DropdownWithSearch
                  id="fromCountry"
                  label="From (Country)"
                  options={allCountries}
                  searchTerm={fromCountryLabel}
                  setSearchTerm={setFromCountryLabel}
                  handleChange={handleLocationChange}
                  selectedOption={values.fromCountry}
                  error={errors?.fromCountry?.value}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  error={Boolean(errors?.fromPostalCode)}
                  id="fromPostalCode"
                  label="Postal Code"
                  helperText={errors?.fromPostalCode}
                  value={values.fromPostalCode}
                  onChange={handlePostalCodeChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Shipping Country and Postal Code */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={{ xs: 2, sm: 1 }}>
              <Grid item xs={12} sm={8}>
                <DropdownWithSearch
                  id="toCountry"
                  label="To (Country)"
                  options={allCountries}
                  searchTerm={toCountryLabel}
                  setSearchTerm={setToCountryLabel}
                  handleChange={handleLocationChange}
                  selectedOption={values.toCountry}
                  error={errors?.toCountry?.value}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  error={Boolean(errors?.toPostalCode)}
                  id="toPostalCode"
                  label="Postal Code"
                  helperText={errors?.toPostalCode}
                  value={values.toPostalCode}
                  onChange={handlePostalCodeChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="column"
          gap="20px"
          width="100%"
          alignItems="flex-start"
        >
          {/* Packages details - quantity, weight, length, width, height */}
          <Stack direction="column" rowGap={1} columnGap={2} width="100%">
            <Typography
              variant="h4"
              fontWeight="500"
              fontSize={isMobile ? "30px" : "36px"}
            >
              Package Details
            </Typography>

            {/* package type toggler */}
            <Stack direction="column">
              <ToggleButtonGroup
                color="primary"
                value={values.packageType}
                exclusive
                onChange={handlePackageTypeChange}
                size="small"
                sx={{ mb: errors?.packageType ? 0 : "15px" }}
              >
                <ToggleButton id="packageType" value="parcel">
                  Parcel
                </ToggleButton>
                <ToggleButton id="packageType" value="document">
                  Document
                </ToggleButton>
              </ToggleButtonGroup>
              {errors?.packageType && (
                <p
                  style={{
                    color: "#d32f2f",
                    fontSize: "10px",
                    lineHeight: "17px",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.packageType}
                </p>
              )}
            </Stack>

            <PackageTextFields
              values={values}
              errors={errors}
              removePackage={removePackage}
              handlePackageChange={handlePackageChange}
              isUkToUkPackage={
                values?.fromCountry?.value === DEFAULT_COUNTRY &&
                values?.toCountry?.value === DEFAULT_COUNTRY
              }
            />
          </Stack>

          {/* add package button */}
          <Button
            variant="contained"
            color="primary"
            onClick={addNewPackage}
            sx={{ maxWidth: "280px", width: "100%" }}
          >
            <span> + </span> &nbsp; ADD PACKAGE
          </Button>
        </Stack>

        <Stack gap="10px">
          {/* T&C checkbox */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.agreedTC}
                  onChange={changeAgreeToTC}
                />
              }
              label={<TC />}
            />
            <span
              style={{
                color: "#d32f2f",
                marginTop: "-10px",
                marginLeft: "30px",
                fontSize: "0.75rem",
                lineHeight: 1.66,
                letterSpacing: "0.03em",
              }}
            >
              {errors?.agreedTC}
            </span>
          </FormControl>

          {/* Get Quotation button */}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ maxWidth: "280px", width: "100%" }}
          >
            GET QUOTES
          </Button>
        </Stack>
      </form>
    </>
  );
};
export default PackageDetailsPersonal;
