import * as Yup from "yup";

const queryDetailsSchema = Yup.object({
  name: Yup.string().required("Required").max(20),
  phoneNo: Yup.string().required("Required").max(19),
  email: Yup.string().email().required("Required").max(80),
  query: Yup.string().required("Required"),
});

export default queryDetailsSchema;
