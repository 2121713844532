import { useSelector } from "react-redux";

import {
  selectPackages,
  selectPackageType,
  selectBillingDetails,
  selectShippingDetails,
} from "../../app/PersonalPackageSlice";

// utils
import { DEFAULT_COUNTRY } from "../../utils/constants";
import { getCountryNameFromCode } from "../../utils/getPlaces";

const InitialValues = () => {
  const packageList = useSelector(selectPackages);
  const packageType = useSelector(selectPackageType);
  const billingDetails = useSelector(selectBillingDetails);
  const shippingDetails = useSelector(selectShippingDetails);

  return {
    fromCountry: {
      label: getCountryNameFromCode(billingDetails?.country || DEFAULT_COUNTRY),
      value: billingDetails?.country || DEFAULT_COUNTRY,
    },
    fromPostalCode: (billingDetails?.postalCode).trim() || "",
    toCountry: {
      label: shippingDetails?.country
        ? getCountryNameFromCode(shippingDetails?.country)
        : "",
      value: shippingDetails?.country || "",
    },
    toPostalCode: (shippingDetails?.postalCode).trim() || "",
    packageType: packageType || "",
    packages: packageList,
    agreedTC: false,
  };
};
export default InitialValues;
