import * as Yup from "yup";

import billingSchema from "./billingValidationSchema";
import shippingSchema from "./shippingValidationSchema";

const address = Yup.object().shape({
  billing: billingSchema,
  shipping: shippingSchema,
});

export default address;
