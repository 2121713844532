import { useFormik } from "formik";
import InitialValues from "./defaultFormStates";
import queryDetailsSchema from "./validationSchema";
import { actionSendQueryDetails } from "../../app/PersonalPackageSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const FormHelper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loaderOpen, setLoaderOpen] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);

  const queryDetailsForm = useFormik({
    validationSchema: queryDetailsSchema,
    enableReinitialize: true,
    initialValues: InitialValues(),
    onSubmit: async (values) => {
      setLoaderOpen(true);
      if (values) {
        const payload = {
          name: values.name,
          phoneNo: values.phoneNo,
          email: values.email,
          query: values.query,
        };

        sendQueryDetails(payload);
      }
    },
  });

  const { errors, values } = queryDetailsForm;

  const handleSubmit = (e) => {
    e.preventDefault();
    return queryDetailsForm.handleSubmit();
  };
  const handleChange = (e) =>
    queryDetailsForm.setFieldValue(e.target.id, e.target.value);

  const sendQueryDetails = async (payload) => {
    try {
      const response = await dispatch(actionSendQueryDetails(payload));
      if (response.type === "sendQueryDetails/fulfilled") {
        try {
          if (response.payload) {
            setLoaderOpen(false);
            navigate(response.payload.redirect_url, { replace: true });
          }
        } catch (error) {
          setLoaderOpen(false);
          setPopUpOpen(true);
          console.log("error-while-go-to-next-page", error);
        }
      }
      if (response.type === "sendQueryDetails/rejected") {
        setLoaderOpen(false);
        setPopUpOpen(true);
        console.log("error-while-fetch-shipping-providers", response.error);
      }
    } catch (error) {
      setLoaderOpen(false);
      setPopUpOpen(true);
      console.log("error-after-fetch-shipping-providers", error);
    }
  };

  return {
    handleSubmit,
    errors,
    values,
    handleChange,
    loaderOpen,
    popUpOpen,
    setPopUpOpen,
  };
};

export default FormHelper;
