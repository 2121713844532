import * as Yup from "yup";

const packageValidationSchemas = (isLengthLimitLessThan100cm = true) => {
  const baseValidationSchema = {
    quantity: Yup.number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedValue = Number(originalValue);
          if (isNaN(parsedValue)) {
            return undefined;
          }
          return parsedValue;
        }
        return value;
      })
      .min(1, "valid quantity is required")
      .required("valid quantity is required"),
    weight: Yup.number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedValue = Number(originalValue);
          if (isNaN(parsedValue)) {
            return undefined;
          }
          return parsedValue;
        }
        return value;
      })
      .min(0.1, "valid weight is required")
      .max(100, "weight must not be greater than 100")
      .required("valid weight is required"),
    itemDescription: Yup.string().required("Required"),
  };

  const isUkToUkAndLessThan100cmSchema = {
    length: Yup.number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedValue = Number(originalValue);
          if (isNaN(parsedValue)) {
            return undefined;
          }
          return parsedValue;
        }
        return value;
      })
      .min(1, "length can not be less than 1")
      .max(100, `length can not be greater than 100`)
      .required("valid length is required"),
    width: Yup.number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedValue = Number(originalValue);
          if (isNaN(parsedValue)) {
            return undefined;
          }
          return parsedValue;
        }
        return value;
      })
      .min(1, "width can not be less than 1")
      .max(60, "width can not be greater than 60")
      .required("valid width is required"),
    height: Yup.number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedValue = Number(originalValue);
          if (isNaN(parsedValue)) {
            return undefined;
          }
          return parsedValue;
        }
        return value;
      })
      .min(1, "height can not be less than 1")
      .max(60, "height can not be greater than 60")
      .required("valid height is required"),
  };

  return Yup.array()
    .of(
      Yup.object({
        ...baseValidationSchema,
        ...(isLengthLimitLessThan100cm && isUkToUkAndLessThan100cmSchema),
      })
    )
    .min(1, "At least one package is required.");
};

export default packageValidationSchemas;
