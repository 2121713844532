import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import DeliveryType from "./DeliveryType";
import DeliveryWithin from "./DeliveryWithin";

const ProviderDetail = ({ orderInfo, chipLabel }) => {
  const shippingProviders = {
    dhl: {
      imageSrc: "/DHL-Logo.png",
      imageAlt: "DHL - courier provider logo",
    },
    indiapost: {
      imageSrc: "/indiapost.png",
      imageAlt: "IndiaPost - courier provider logo",
    },
    ups: {
      imageSrc: "/ups.png",
      imageAlt: "UPS - courier provider logo",
    },
    evri: {
      imageSrc: "/evri-logo.png",
      imageAlt: "EVRI - courier provider logo",
    },
    dhl_evri: {
      imageSrc: "/evri-logo.png",
      imageAlt: "DHL & Evri - courier provider logo",
    },
  };

  const chipLabels = {
    old: { label: "Old Price", color: "error" },
    new: { label: "Updated Price", color: "success" },
  };

  return (
    <Stack spacing={2}>
      <Card
        sx={{ width: "100%", maxWidth: "max-content", padding: "0 30px 0 0px" }}
      >
        <CardContent>
          <Stack alignItems="flex-start" gap="20px">
            {chipLabel && (
              <Chip
                label={chipLabels?.[chipLabel]?.label}
                color={chipLabels?.[chipLabel]?.color}
                variant="filled"
                sx={{
                  p: 1,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              />
            )}
            <Stack direction="row" alignItems="center" spacing={1}>
              <img
                style={{ objectFit: "cover" }}
                className="quote-logo"
                src={
                  shippingProviders?.[orderInfo?.providerCompany]?.imageSrc ||
                  ""
                }
                alt={
                  shippingProviders?.[orderInfo?.providerCompany]?.imageAlt ||
                  ""
                }
              />
              <Typography fontWeight="bold" variant="h6">
                {orderInfo.providerName}
              </Typography>
            </Stack>
            <DeliveryType deliveryType={orderInfo?.deliveryType} />
            <Stack>
              <DeliveryWithin
                providerCompany={orderInfo?.providerCompany}
                timeTaken={orderInfo?.timeTaken}
              />
              <Typography fontWeight="bold" variant="h5">
                £ {orderInfo?.price}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ProviderDetail;
