import PropTypes from "prop-types";

// mui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

// icons
import { AiFillDelete } from "react-icons/ai";

// utils
import { DEFAULT_LENGTH_LIMIT, lengthLimits } from "../../utils/constants";

const PackageTextFields = ({
  values,
  errors,
  removePackage,
  handlePackageChange,
  isAdminPage = false,
  isUkToUkPackage = false,
}) => {
  const { packages } = values;

  const handlePackageRemove = (id) => removePackage(id);

  return packages.map(
    (
      {
        quantity,
        weight,
        length,
        width,
        height,
        itemDescription,
        sku,
        lengthLimit,
      },
      idx
    ) => {
      const isLimitLessThan100cm = lengthLimit === DEFAULT_LENGTH_LIMIT;
      const hideExtendedPackageDimensionsField =
        !isLimitLessThan100cm && isUkToUkPackage;

      return (
        <Stack
          key={sku}
          direction="column"
          columnGap="10px"
          rowGap="20px"
          width="100%"
        >
          {packages?.length > 1 &&
            (isAdminPage ? (
              <Typography variant="h6" gutterBottom>
                Package {idx + 1}
              </Typography>
            ) : (
              // remove package button
              <Stack direction="row" alignItems="center" columnGap="20px">
                <Typography>Package {idx + 1}</Typography>
                <Button
                  sx={{ maxHeight: "56px" }}
                  variant="text"
                  color="error"
                  id={`removePackageBtn-${sku}`}
                  startIcon={<AiFillDelete />}
                  onClick={() => handlePackageRemove(idx)}
                >
                  Remove Package
                </Button>
              </Stack>
            ))}

          <Grid container rowSpacing={2} columnSpacing={1}>
            {isUkToUkPackage && (
              <Grid xs={12} sm={6} md={3} lg={2.4} item>
                <FormControl fullWidth>
                  <InputLabel>length</InputLabel>
                  <Select
                    name="lengthLimit"
                    value={lengthLimit}
                    label="length"
                    id={`lengthLimit-${sku}`}
                    fullWidth
                    onChange={(e) => handlePackageChange(e, idx)}
                    defaultValue={DEFAULT_LENGTH_LIMIT}
                  >
                    {lengthLimits.map((lengthLimit) => (
                      <MenuItem
                        key={lengthLimit.value}
                        value={lengthLimit.value}
                      >
                        {lengthLimit.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid xs={6} sm={6} md={3} lg={2.4} item>
              <TextField
                error={!!errors?.packages?.[idx]?.quantity}
                id={`quantity-${sku}`}
                name="quantity"
                label="Quantity"
                fullWidth
                helperText={errors?.packages?.[idx]?.quantity}
                value={quantity}
                onChange={(e) => handlePackageChange(e, idx)}
              />
            </Grid>

            <Grid xs={6} sm={6} md={3} lg={2.4} item>
              <TextField
                error={!!errors?.packages?.[idx]?.weight}
                id={`weight-${sku}`}
                name="weight"
                label="Weight (kg)"
                fullWidth
                helperText={errors?.packages?.[idx]?.weight}
                value={weight}
                onChange={(e) => handlePackageChange(e, idx)}
              />
            </Grid>

            {!hideExtendedPackageDimensionsField && (
              <>
                <Grid xs={6} sm={6} md={3} lg={2.4} item>
                  <TextField
                    error={!!errors?.packages?.[idx]?.length}
                    id={`length-${sku}`}
                    name="length"
                    label="Length (cm)"
                    fullWidth
                    helperText={errors?.packages?.[idx]?.length}
                    value={length}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Grid>

                <Grid xs={6} sm={6} md={3} lg={2.4} item>
                  <TextField
                    error={!!errors?.packages?.[idx]?.width}
                    id={`width-${sku}`}
                    name="width"
                    label="Width (cm)"
                    fullWidth
                    helperText={errors?.packages?.[idx]?.width}
                    value={width}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Grid>

                <Grid xs={6} sm={6} md={3} lg={2.4} item>
                  <TextField
                    error={!!errors?.packages?.[idx]?.height}
                    id={`height-${sku}`}
                    name="height"
                    label="Height (cm)"
                    fullWidth
                    helperText={errors?.packages?.[idx]?.height}
                    value={height}
                    onChange={(e) => handlePackageChange(e, idx)}
                  />
                </Grid>
              </>
            )}

            <Grid xs={12} sm={12} md={6} lg={4.8} item>
              <TextField
                error={!!errors?.packages?.[idx]?.itemDescription}
                id={`item-description-${sku}`}
                name="itemDescription"
                label="Item Description"
                fullWidth
                helperText={errors?.packages?.[idx]?.itemDescription}
                value={itemDescription}
                onChange={(e) => handlePackageChange(e, idx)}
                sx={{ marginBottom: "15px" }}
              />
            </Grid>
          </Grid>
        </Stack>
      );
    }
  );
};

PackageTextFields.propTypes = {
  values: PropTypes.shape({
    quantity: PropTypes.number,
    weight: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    itemDescription: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    quantity: PropTypes.oneOf([PropTypes.string, null]),
    weight: PropTypes.oneOf([PropTypes.string, null]),
    length: PropTypes.oneOf([PropTypes.string, null]),
    height: PropTypes.oneOf([PropTypes.string, null]),
    itemDescription: PropTypes.oneOf([PropTypes.string, null]),
  }),
  isAdminPage: PropTypes.bool,
  removePackage: PropTypes.func,
  isUkToUkPackage: PropTypes.bool,
  handlePackageChange: PropTypes.func.isRequired,
};

PackageTextFields.defaultProps = {
  errors: {},
  isAdminPage: false,
  isUkToUkPackage: false,
};

export default PackageTextFields;
