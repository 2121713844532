import * as Yup from "yup";

import noPostalCodes from "../../utils/noPostalCodes";
import packageValidationSchemas from "./packagesSchema";

const stringBaseValidation = Yup.string();

const packageDetailsSchema = (
  isLengthLimitLessThan100cm = true,
  fromCountry,
  toCountry
) => {
  return Yup.object({
    fromCountry: Yup.object({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
    fromPostalCode: noPostalCodes?.[fromCountry]
      ? stringBaseValidation
      : stringBaseValidation.required("Required"),
    toCountry: Yup.object({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
    toPostalCode: noPostalCodes?.[toCountry]
      ? stringBaseValidation
      : stringBaseValidation.required("Required"),
    agreedTC: Yup.bool().oneOf([true], "Field must be checked"),
    packageType: Yup.string().required("Required"),
    packages: packageValidationSchemas(isLengthLimitLessThan100cm),
  });
};

export default packageDetailsSchema;
