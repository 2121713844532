import { Autocomplete, TextField } from "@mui/material";

const DropdownWithSearch = ({
  options,
  id,
  label,
  searchTerm,
  selectedOption,
  handleChange,
  setSearchTerm,
  error,
}) => {
  const handleLocationChange = (_, data) => {
    if (!data) return;

    handleChange(id, {
      label: data.label,
      value: data.value,
    });
  };

  return (
    <Autocomplete
      onChange={handleLocationChange}
      value={selectedOption}
      inputValue={searchTerm}
      onInputChange={(_, value) => setSearchTerm(value)}
      disablePortal
      id={id}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          label={label}
        />
      )}
      isOptionEqualToValue={(data) => {
        return selectedOption.value === "" ? data : data.value;
      }}
      fullWidth={true}
    />
  );
};
export default DropdownWithSearch;
