import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 275, sm: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

const UpdateConformationModal = ({
  isOpen,
  handleClose,
  confirmationFunc,
  isConformationLoading,
}) => {
  return (
    <Modal
      aria-labelledby="admin-package-dimensions-update-modal"
      aria-describedby="package-dimensions-update-modal"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Typography
            textAlign="center"
            sx={{ mt: 2, fontWeight: "600" }}
            id="package-dimensions-update-modal"
          >
            Are you sure to update the package details and price.
          </Typography>
          <Stack
            direction="row"
            gap="20px"
            marginTop={2}
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              color="success"
              sx={{
                width: { xs: "100%", sm: "30%" },
                fontWeight: isConformationLoading ? "600" : "",
              }}
              loading={isConformationLoading}
              loadingIndicator={
                <CircularProgress sx={{ color: "grey" }} size={16} />
              }
              endIcon={null}
              loadingPosition="end"
              onClick={confirmationFunc}
              disableElevation
            >
              Confirm
            </LoadingButton>

            <Button
              sx={{
                width: { xs: "100%", sm: "30%" },
              }}
              disableElevation
              variant="contained"
              color="error"
              onClick={handleClose}
              disabled={isConformationLoading}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateConformationModal;
