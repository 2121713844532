import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { MdArrowForward } from "react-icons/md";

import ProviderDetail from "./ProviderDetail";

const ProviderDetails = ({
  isSmallScreen,
  orderInfoShippingProvider,
  updatedOrderInfoShippingProvider,
}) => {
  return (
    <>
      <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom={true}>
        Shipping Provider :
      </Typography>
      <Stack direction="row" gap="50px" alignItems="center">
        {orderInfoShippingProvider && (
          <ProviderDetail
            chipLabel="old"
            isSmallScreen={isSmallScreen}
            orderInfo={orderInfoShippingProvider}
          />
        )}
        {updatedOrderInfoShippingProvider && (
          <Stack gap="50px" direction="row" alignItems="center">
            <MdArrowForward fontSize="30px" />

            <ProviderDetail
              chipLabel="new"
              isSmallScreen={isSmallScreen}
              orderInfo={updatedOrderInfoShippingProvider}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ProviderDetails;
