import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
  actionGoToNextPage,
  actionUpdatePackages,
  selectBillingDetails,
  selectShippingDetails,
  actionUpdateLoqateData,
  actionUpdatePackageType,
  actionValidatePostalCode,
  actionUpdateBillingDetails,
  actionGetShippingProviders,
  actionUpdateShippingDetails,
} from "../../app/PersonalPackageSlice";
import initialValues from "./defaultFormStates";
import packageDetailsSchema from "./validationSchema";

// utils
import {
  routes,
  DEFAULT_COUNTRY,
  DEFAULT_LENGTH_LIMIT,
} from "../../utils/constants";
import noPostalCodes from "../../utils/noPostalCodes";
import { generateSKU } from "../../utils/generateSKU";
import { capitalizeFirstLetter } from "../../utils/functions";
import { getCountryNameFromCode } from "../../utils/getPlaces";
import { hasOverweightOrOversizePackage } from "../../utils/checkOverDimension";

const postalCodeErrorMessage = "Postal code error";

const FormHelper = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [packageYupSchema, setPackageYupSchema] = useState(
    packageDetailsSchema()
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const billingDetails = useSelector(selectBillingDetails);
  const shippingDetails = useSelector(selectShippingDetails);

  const packageDetailsForm = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: initialValues(),
    validationSchema: packageYupSchema,
    onSubmit: async (values) => {
      setLoaderOpen(true);

      const fromPostalPayload = {
        postalCode: values.fromPostalCode.trim(),
        country: values.fromCountry.value ?? billingDetails.country,
      };
      const toPostalPayload = {
        postalCode: values.toPostalCode.trim(),
        country: values.toCountry.value ?? shippingDetails.country,
      };

      dispatch(
        actionUpdateBillingDetails({
          country: values.fromCountry.value,
          postalCode: values.fromPostalCode,
        })
      );
      dispatch(
        actionUpdateShippingDetails({
          country: values.toCountry.value,
          postalCode: values.toPostalCode,
        })
      );

      dispatch(actionUpdatePackages(values.packages));
      dispatch(actionUpdatePackageType(values.packageType));

      if (
        values.fromCountry.value === "GB" &&
        values.toCountry.value === "GB" &&
        hasOverweightOrOversizePackage(values.packages)
      ) {
        return navigate(routes.queryPage);
      }

      const arePostalCodesValid = await validatePostalCode(
        fromPostalPayload,
        toPostalPayload
      );

      if (arePostalCodesValid) {
        dispatch(actionUpdateLoqateData(arePostalCodesValid));
        dispatch(
          actionUpdateBillingDetails({
            state:
              capitalizeFirstLetter(
                arePostalCodesValid?.billing?.AdministrativeArea
              ) || "",
            city:
              capitalizeFirstLetter(arePostalCodesValid?.billing?.Locality) ||
              "",
          })
        );
        dispatch(
          actionUpdateShippingDetails({
            state:
              capitalizeFirstLetter(
                arePostalCodesValid?.shipping?.AdministrativeArea
              ) || "",
            city:
              capitalizeFirstLetter(arePostalCodesValid?.shipping?.Locality) ||
              "",
          })
        );
        const payload = {
          weight: values.packages.reduce(
            (totalWeight, pkg) =>
              totalWeight + parseFloat(pkg.weight * pkg.quantity) || 0,
            0
          ),
          type: values.packageType,
          country_from: values.fromCountry.value,
          country_to: values.toCountry.value,
          from_post_code: values?.fromPostalCode || "",
          to_post_code: values?.toPostalCode || "",
          items: values.packages,
          shippingCity:
            arePostalCodesValid?.shipping?.Locality?.toLowerCase() || "",
        };
        fetchShippingProviders(payload);
      } else {
        setLoaderOpen(false);
      }
    },
  });

  const { errors, values, setFieldError, setFieldValue } = packageDetailsForm;

  useEffect(() => {
    const isAnyPackageLengthLimitMoreThan100cm = values.packages.some(
      (pack) => pack.lengthLimit !== DEFAULT_LENGTH_LIMIT
    );

    setPackageYupSchema(
      packageDetailsSchema(
        !isAnyPackageLengthLimitMoreThan100cm,
        values.fromCountry.value,
        values.toCountry.value
      )
    );
  }, [values.fromCountry.value, values.packages, values.toCountry.value]);

  const handleSubmit = (e) => {
    e.preventDefault();
    return packageDetailsForm.handleSubmit();
  };

  const handlePostalCodeChange = (e) => {
    const id = e.target.id;
    const value = e.target.value.toUpperCase();

    if (["fromPostalCode", "toPostalCode"].includes(id)) {
      setFieldError(id, null);
      packageDetailsForm.setFieldValue(id, value);
    }
  };

  const setDefaultToUK = (field) => {
    packageDetailsForm.setFieldValue(field, {
      value: DEFAULT_COUNTRY,
      label: getCountryNameFromCode(DEFAULT_COUNTRY),
    });
  };

  const handleLocationChange = (field, data) => {
    if (["fromCountry", "toCountry"].includes(field)) {
      setFieldValue(field, {
        value: data.value,
        label: data.label,
      });

      if (field === "fromCountry") {
        setFieldValue("fromPostalCode", "");

        if (data.value !== DEFAULT_COUNTRY) {
          setDefaultToUK("toCountry");

          if (packageDetailsForm.values.toCountry.value !== DEFAULT_COUNTRY)
            setFieldValue("toPostalCode", "");
        }
      }

      if (field === "toCountry") {
        setFieldValue("toPostalCode", "");

        if (data.value !== DEFAULT_COUNTRY) {
          setDefaultToUK("fromCountry");

          if (packageDetailsForm.values.fromCountry.value !== DEFAULT_COUNTRY)
            setFieldValue("fromPostalCode", "");
        }
      }

      setFieldError(field, null);
    }
  };

  const changeAgreeToTC = () => {
    const field = "agreedTC";
    setFieldValue(field, !packageDetailsForm.values.agreedTC);
    setFieldError(field, null);
  };

  const handlePackageTypeChange = (e) => {
    const { value, id } = e.target;
    if (value === "document") {
      setFieldValue(
        "packages",
        values?.packages?.map((p) => ({
          ...p,
          itemDescription: p?.itemDescription || "Documents",
        }))
      );
      setFieldError(
        "packages",
        errors?.packages?.map((p) => ({
          ...p,
          itemDescription: null,
        }))
      );
    }
    setFieldValue(id, value);
    setFieldError(id, null);
  };

  const addNewPackage = () =>
    setFieldValue("packages", [
      ...values.packages,
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        itemDescription: values.packageType === "document" ? "Documents" : "",
        sku: generateSKU(),
        lengthLimit: DEFAULT_LENGTH_LIMIT,
      },
    ]);

  const handlePackageChange = (e, idx) => {
    setFieldValue(
      "packages",
      values?.packages?.map((eachPackage, index) =>
        index === idx
          ? { ...eachPackage, [e.target.name]: e.target.value }
          : eachPackage
      )
    );
    setFieldError(
      "packages",
      errors?.packages?.map((eachPackage, index) =>
        index === idx ? { ...eachPackage, [e.target.name]: null } : eachPackage
      )
    );
  };

  const removePackage = (id) => {
    const updatedPackagesForm = [...packageDetailsForm.values.packages];
    updatedPackagesForm.splice(id, 1);
    packageDetailsForm.setFieldValue("packages", updatedPackagesForm);
  };

  const validatePostalCode = async (fromPostalPayload, toPostalPayload) => {
    try {
      const apiBodyVal = { from: null, to: null };

      if (!noPostalCodes?.[fromPostalPayload.country]) {
        apiBodyVal.from = {
          Locality: fromPostalPayload.postalCode,
          Country: fromPostalPayload.country,
        };
      }

      if (!noPostalCodes?.[toPostalPayload.country]) {
        apiBodyVal.to = {
          Locality: toPostalPayload.postalCode,
          Country: toPostalPayload.country,
        };
      }

      const response = await dispatch(actionValidatePostalCode(apiBodyVal));

      if (response.type === "validatePostalCode/fulfilled") {
        try {
          if (response.payload.length === 0) {
            setFieldError("fromPostalCode", postalCodeErrorMessage);
            setFieldError("toPostalCode", postalCodeErrorMessage);
            return false;
          }

          const data = {
            billing: response.payload[0],
            shipping: response.payload[1],
          };

          const shippingError =
            apiBodyVal.to === null
              ? false
              : !data?.shipping?.Locality ||
                data?.shipping?.Locality?.toLowerCase() ===
                  toPostalPayload?.postalCode?.toLowerCase();

          const billingError =
            apiBodyVal.from === null
              ? false
              : !data?.billing?.Locality ||
                data?.billing?.Locality?.toLowerCase() ===
                  fromPostalPayload?.postalCode?.toLowerCase();

          if (shippingError) {
            setFieldError("toPostalCode", postalCodeErrorMessage);
          }
          if (billingError) {
            setFieldError("fromPostalCode", postalCodeErrorMessage);
          }

          if (shippingError || billingError) {
            setLoaderOpen(false);
            return false;
          }

          return data;
        } catch (error) {
          setLoaderOpen(false);
          setFieldError("fromPostalCode", postalCodeErrorMessage);
          setFieldError("toPostalCode", postalCodeErrorMessage);
          console.log("error-while-go-to-next-page", error);
          return false;
        }
      }

      if (response.type === "validatePostalCode/rejected") {
        setFieldError("fromPostalCode", postalCodeErrorMessage);
        setFieldError("toPostalCode", postalCodeErrorMessage);
        console.log("error-while-fetch-shipping-providers", response.error);
        return false;
      }
    } catch (error) {
      setLoaderOpen(false);
      setPopUpOpen(true);
      console.log("error-after-fetch-shipping-providers", error);
      return false;
    }
  };

  const fetchShippingProviders = async (payload) => {
    try {
      const response = await dispatch(actionGetShippingProviders(payload));
      if (response.type === "shipping/getShippingProviders/fulfilled") {
        try {
          dispatch(actionGoToNextPage());
        } catch (error) {
          setLoaderOpen(false);
          setPopUpOpen(true);
          console.log("error-while-go-to-next-page", error);
        }
      }

      if (response.type === "shipping/getShippingProviders/rejected") {
        setLoaderOpen(false);
        setPopUpOpen(true);
        console.log("error-while-fetch-shipping-providers", response.error);
      }
    } catch (error) {
      setLoaderOpen(false);
      setPopUpOpen(true);
      console.log("error-after-fetch-shipping-providers", error);
    }
  };

  return {
    handleSubmit,
    handlePostalCodeChange,
    handleLocationChange,
    changeAgreeToTC,
    handlePackageTypeChange,
    addNewPackage,
    handlePackageChange,
    removePackage,
    errors,
    values,
    loaderOpen,
    popUpOpen,
    setPopUpOpen,
  };
};

export default FormHelper;
