import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, Outlet } from "react-router-dom";

// constants
import { ADMIN_LOGIN_COOKIE, routes } from "../../utils/constants";

const NoAdminCookie = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([ADMIN_LOGIN_COOKIE]);

  useEffect(() => {
    if (cookies?.[ADMIN_LOGIN_COOKIE]) {
      return navigate(routes.adminPackageUpdatePage);
    }
  }, [cookies, navigate]);

  return <Outlet />;
};

export default NoAdminCookie;
