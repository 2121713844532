import axios from "axios";

const apiFactory = ({
  baseURL = process.env.REACT_APP_BASE_URL,
  headers = null,
  timeout = 9000,
  responseType = "json",
  responseEncoding = "utf8",
  ...rest
} = {}) => {
  const instance = axios.create({
    baseURL,
    timeout,
    headers,
    responseType,
    responseEncoding,
    ...rest,
  });

  instance.interceptors.request.use(
    (config) => {
      config.headers.Accept = "application/json";

      return config;
    },
    (err) => Promise.reject(err)
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => Promise.reject(err)
  );

  return instance;
};

export default apiFactory;
