import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BsFillBoxSeamFill } from "react-icons/bs";

const OrderInfoPackages = ({ package: packages, index }) => {
  return (
    <Box display="grid" gridTemplateColumns="0.1fr 1.9fr" gap={1}>
      <Grid>
        <BsFillBoxSeamFill />
      </Grid>
      <Grid>
        <Typography variant="h6" gutterBottom={true}>
          <b>Package {index + 1}</b>
          {packages?.quantity && (
            <Typography
              variant="subtitle1"
              component="span"
              display="inline"
              sx={{ ml: 1 }}
            >
              x<span style={{ marginLeft: "5px" }}>{packages?.quantity}</span>
            </Typography>
          )}
        </Typography>
        {packages?.height && packages?.width && packages?.length ? (
          <Typography variant="subtitle2" gutterBottom={true}>
            Dimensions: <b>{packages?.weight} kg</b> |{" "}
            <b>{packages?.height} cm</b> x <b>{packages?.width} cm</b> x{" "}
            <b>{packages?.length} cm</b>
          </Typography>
        ) : (
          <Typography variant="subtitle2" gutterBottom={true}>
            Weight: <b>{packages?.weight} kg</b>
          </Typography>
        )}
      </Grid>
    </Box>
  );
};
export default OrderInfoPackages;
