import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { IoIosArrowBack } from "react-icons/io";

// components
import PopUpModal from "../PopUpModal/PopUpModal";
import ScreenLoader from "../ScreenLoader/ScreenLoader";

// utils
import { hasOverweightOrOversizePackage } from "../../utils/checkOverDimension";

// others
import FormHelper from "./formHelper";
import { selectPackages } from "../../app/PersonalPackageSlice";

const QueryPageForm = () => {
  const navigate = useNavigate();
  const packageList = useSelector(selectPackages);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    loaderOpen,
    popUpOpen,
    setPopUpOpen,
  } = FormHelper();

  useEffect(() => {
    const isQueryMessageApplicable =
      hasOverweightOrOversizePackage(packageList);
    if (!isQueryMessageApplicable) {
      navigate("/");
    }
  }, [packageList, navigate]);

  const handlePopUpClose = () => setPopUpOpen(false);
  return (
    <>
      <ScreenLoader open={loaderOpen} />
      <PopUpModal
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        as={"BACKEND-ERROR"}
      />

      <div
        style={{
          display: "flex",
        }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          width={"100%"}
          padding={"10px"}
          paddingX={"30px"}
        >
          {" "}
          <h1>Package Limitations</h1>
          <h6>Have a question or a query? We're here to help!</h6>
          <p>
            Use the form to submit your inquiries, and our team of experts will
            get back to you as soon as possible. Please note that packages
            exceeding <strong>30kg in weight</strong> or{" "}
            <strong>100cm in length</strong> may have special handling
            requirements.
          </p>
          <Stack
            marginTop={"5px"}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2 }}
            justifyContent="space-between"
            alignItems={{ xs: "stretch", sm: "center" }}
          >
            <Button
              startIcon={<IoIosArrowBack />}
              variant="contained"
              color="warning"
              onClick={() => {
                navigate("/");
              }}
            >
              Change Package Data
            </Button>
          </Stack>
        </Stack>
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            padding: "10px 30px 0px 30px",
            marginTop: "30px",
            marginBottom: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Stack direction={"column"} gap={2} width={"100%"} padding={"10px"}>
            <TextField
              error={!!errors?.name}
              id="name"
              label="Name"
              helperText={errors?.name}
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              error={!!errors?.phoneNo}
              id="phoneNo"
              label="Phone No"
              helperText={errors?.phoneNo}
              value={values.phoneNo}
              onChange={handleChange}
            />
            <TextField
              error={!!errors?.email}
              id="email"
              label="Email"
              helperText={errors?.email}
              value={values.email}
              onChange={handleChange}
            />
            <TextField
              id="query"
              label="Detailed description of query"
              placeholder="Query"
              multiline
              rows={4}
              error={!!errors?.query}
              helperText={errors?.query}
              value={values.query}
              onChange={handleChange}
            />
            <Button variant="contained" color="primary" type="submit">
              SUBMIT
            </Button>
          </Stack>
        </form>
      </div>
    </>
  );
};

export default QueryPageForm;
