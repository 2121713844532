import { useState } from "react";
import { useCookies } from "react-cookie";

// context
import { AuthContext } from "../context/AuthContext";

// configs
import apiFactory from "../configs/axios";

// utils
import { notify } from "../utils/globalToast";
import { ADMIN_LOGIN_COOKIE, serverRoutes } from "../utils/constants";

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([ADMIN_LOGIN_COOKIE]);

  const adminCookie = cookies?.[ADMIN_LOGIN_COOKIE];

  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(adminCookie));
  const [isAdmin, setIsAdmin] = useState(Boolean(adminCookie));

  const adminLogin = async (values) => {
    const fetcher = apiFactory();

    try {
      const response = await fetcher.post(serverRoutes.adminLogin, values);

      if (
        response?.status === 200 &&
        response?.data?.message === "Admin Login success"
      ) {
        setCookie(ADMIN_LOGIN_COOKIE, response?.data?.data, {
          sameSite: true,
          secure: true,
          path: "/",
        });
        setIsAuthenticated(true);
        setIsAdmin(true);
        notify("success", "Logged in successfully");
        return true;
      }
    } catch (error) {
      notify(
        "error",
        error.response.status === 403
          ? "Incorrect password"
          : "Something went wrong. Please try again later"
      );
      return false;
    }
  };

  const adminLogout = () => {
    setIsAuthenticated(false);
    setIsAdmin(false);
    removeCookie(ADMIN_LOGIN_COOKIE);
  };

  const values = {
    isAuthenticated,
    isAdmin,
    adminLogin,
    adminLogout,
    token: adminCookie,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
