import { Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
// redux
import { actionGoToPreviousPage } from "../../app/PersonalPackageSlice";
// components
import ShippingForm from "./ShippingForm";
import BillingForm from "./BillingForm";
import FormHelper from "./FormHelper";
// styles
import "./AddressDetailsPersonal.scss";

const AddressDetailsPersonal = () => {
  const dispatch = useDispatch();

  const { handleSubmit, values, errors, handleChange } = FormHelper();

  const goToPreviousPage = () => dispatch(actionGoToPreviousPage());

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        padding: "10px",
        marginTop: "30px",
        marginBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
      }}
    >
      <BillingForm
        billingErrors={errors?.billing}
        billingValues={values?.billing}
        handleChange={handleChange}
      />
      <ShippingForm
        shippingErrors={errors?.shipping}
        shippingValues={values?.shipping}
        handleChange={handleChange}
      />

      <Stack
        width="100%"
        flexDirection={{ sm: "column-reverse", md: "row" }}
        gap="10px"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="contained" color="info" onClick={goToPreviousPage}>
          SELECT ANOTHER QUOTE
        </Button>
        <Button type="submit" variant="contained" color="success">
          GO TO NEXT PAGE
        </Button>
      </Stack>
    </form>
  );
};
export default AddressDetailsPersonal;
