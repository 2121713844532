import { useSelector } from "react-redux";

// redux
import {
  selectBillingDetails,
  selectShippingDetails,
} from "../../app/PersonalPackageSlice";

const InitialValues = () => {
  const shippingDetails = useSelector(selectShippingDetails);
  const billingDetails = useSelector(selectBillingDetails);

  return {
    billing: {
      firstName: billingDetails?.firstName || "",
      lastName: billingDetails?.lastName || "",
      email: billingDetails?.email || "",
      phoneNo: billingDetails?.phoneNo || "",
      companyName: billingDetails?.companyName || "",
      country: billingDetails?.country || "",
      state: billingDetails?.state || "",
      city: billingDetails?.city || "",
      postalCode: billingDetails?.postalCode || "",
      address1: billingDetails?.address1 || "",
    },
    shipping: {
      firstName: shippingDetails?.firstName || "",
      lastName: shippingDetails?.lastName || "",
      email: shippingDetails?.email || "",
      phoneNo: shippingDetails?.phoneNo || "",
      companyName: billingDetails?.companyName || "",
      country: shippingDetails?.country || "",
      state: shippingDetails?.state || "",
      city: shippingDetails?.city || "",
      postalCode: shippingDetails?.postalCode || "",
      address1: shippingDetails?.address1 || "",
    },
  };
};
export default InitialValues;
