import "./ProgressStep.scss";

const ProgressStep = ({ stepIndex, title, navigationList }) => {
  return (
    <div className="progress-step">
      <div className="progress-step__title">
        <h2>
          <span>{title}</span> Quote
        </h2>
        <p>
          Impact Express provides cost effective Express Parcel Delivery
          solutions to over 220 destinations worldwide, fully tracked from our
          website or app.
        </p>
      </div>

      <ul className="list-unstyled multi-steps">
        {navigationList.map((nav, idx) => (
          <li key={nav.name} className={stepIndex === idx ? "is-active" : ""}>
            {nav.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ProgressStep;
