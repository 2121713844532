import dayjs from "dayjs";
import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// utils
import { generateSKU } from "../utils/generateSKU";
import { DEFAULT_LENGTH_LIMIT } from "../utils/constants";

const currentDateTime =
  dayjs().add(1, "day").day() === 6
    ? dayjs().add(2, "day").format("YYYY-MM-DD 00:00:00")
    : dayjs()
        .add(dayjs().day() === 0 ? 1 : 1, "day")
        .format("YYYY-MM-DD 00:00:00");

const nonRestrictDateTime =
  dayjs().add(1, "day").day() === 6
    ? dayjs().add(2, "day").format("YYYY-MM-DD 00:00:00")
    : dayjs()
        .add(dayjs().day() === 0 ? 1 : 1, "day")
        .format("YYYY-MM-DD 00:00:00");

const initialState = {
  currentPage: 0,
  billingDetails: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    address1: "",
  },
  shippingDetails: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    address1: "",
  },
  packageType: "",
  packages: [
    {
      quantity: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      itemDescription: "",
      sku: generateSKU(),
      lengthLimit: DEFAULT_LENGTH_LIMIT,
    },
  ],
  quotes: [],
  deliveryOption: {},
  isLoading: false,
  selectedCollectionDateTime: dayjs(currentDateTime),
  nonRestrictDateTime: dayjs(nonRestrictDateTime),
  selectedCollectionTime: "",
  isLabelGenerated: false,
  // !NOTE: if in future this field is not require remove it. Now keeping it ust for testing purposes
  loqateData: {},
};

export const actionGetShippingProviders = createAsyncThunk(
  "shipping/getShippingProviders",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/shipping/providers`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const actionSendQueryDetails = createAsyncThunk(
  "sendQueryDetails",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/query/submit`,
        payload
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const actionValidatePostalCode = createAsyncThunk(
  "validatePostalCode",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/validate/postcode`,
        payload
      );

      return response.data?.data || null;
    } catch (error) {
      throw error;
    }
  }
);

export const personalPackageSlice = createSlice({
  name: "personalPackageSlice",
  initialState,
  reducers: {
    actionUpdateQuoteLocation: (state, action) => {
      state.quoteLocation.fromCountry = action.payload?.fromCountry;
      state.quoteLocation.fromPostalCode = action.payload?.fromPostalCode;
      state.quoteLocation.toCountry = action.payload?.toCountry;
      state.quoteLocation.toPostalCode = action.payload?.toPostalCode;
    },

    actionUpdateSenderDetails: (state, action) => {
      state.senderDetails = action.payload;
    },
    actionUpdateReceiverDetails: (state, action) => {
      state.receiverDetails = action.payload;
    },
    actionUpdateDeliveryOption: (state, action) => {
      state.deliveryOption = action.payload;
    },
    actionUpdateBillingAddress: (state, action) => {
      state.senderDetails.billingAddress = {
        ...state.senderDetails.billingAddress,
        ...action.payload,
      };
    },
    actionUpdateShippingAddress: (state, action) => {
      state.receiverDetails.shippingAddress = {
        ...state.receiverDetails.shippingAddress,
        ...action.payload,
      };
    },
    actionUpdateAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    actionUpdateShippingDetails: (state, action) => {
      state.shippingDetails = {
        ...state.shippingDetails,
        ...action.payload,
      };
    },
    actionUpdateBillingDetails: (state, action) => {
      state.billingDetails = {
        ...state.billingDetails,
        ...action.payload,
      };
    },
    actionUpdatePackages: (state, action) => {
      state.packages = action.payload;
    },
    actionGoToNextPage: (state) => {
      state.currentPage = state.currentPage + 1;
    },
    actionGoToPreviousPage: (state) => {
      if (state.currentPage === 0) return;
      state.currentPage = state.currentPage - 1;
    },
    actionJumpToPage: (state, action) => {
      state.currentPage = action.payload;
    },
    actionUpdatePackageType: (state, action) => {
      state.packageType = action.payload;
    },
    actionUpdateSelectedCollectionDateTime: (state, action) => {
      state.selectedCollectionDateTime = action.payload;
    },
    actionUpdateSelectedCollectionTime: (state, action) => {
      state.selectedCollectionTime = action.payload;
    },
    actionUpdateNonRestrictDateTime: (state, action) => {
      state.nonRestrictDateTime = action.payload;
    },
    actionUpdateLabelGenerateStatus: (state, action) => {
      state.isLabelGenerated = action.payload;
    },
    actionUpdateLoqateData: (state, action) => {
      state.loqateData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionGetShippingProviders.fulfilled, (state, action) => {
        state.quotes = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(actionGetShippingProviders.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionGetShippingProviders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionSendQueryDetails.fulfilled, (state, action) => {
        state.quotes = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(actionSendQueryDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionSendQueryDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionValidatePostalCode.fulfilled, (state, action) => {
        if (action.payload?.Items) {
          state.isLoading = false;
        }
      })
      .addCase(actionValidatePostalCode.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionValidatePostalCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  actionUpdateQuoteLocation,
  actionUpdatePackages,
  actionUpdateSenderDetails,
  actionUpdateReceiverDetails,
  actionUpdateDeliveryOption,
  actionUpdateBillingAddress,
  actionUpdateShippingAddress,
  actionUpdateAllCountries,
  actionUpdateSelectedCollectionDateTime,
  actionUpdateNonRestrictDateTime,
  actionUpdateSelectedCollectionTime,
  actionUpdateLabelGenerateStatus,
  actionUpdateShippingDetails,
  actionUpdateBillingDetails,
  actionGoToNextPage,
  actionGoToPreviousPage,
  actionJumpToPage,
  actionUpdatePackageType,
  actionUpdateLoqateData,
} = personalPackageSlice.actions;

export const selectBillingDetails = (state) =>
  state.personalPackage.billingDetails;
export const selectShippingDetails = (state) =>
  state.personalPackage.shippingDetails;
export const selectPackages = (state) => state.personalPackage.packages;
export const selectPackageType = (state) => state.personalPackage.packageType;
export const selectCurrentPage = (state) => state.personalPackage.currentPage;

export const selectQuoteLocation = (state) =>
  state.personalPackage.quoteLocation;
export const selectDeliveryOption = (state) =>
  state.personalPackage.deliveryOption;
export const selectQuotes = (state) => state.personalPackage.quotes;

export const selectCollectionDateTime = (state) =>
  state.personalPackage.selectedCollectionDateTime;
export const selectCollectionTime = (state) =>
  state.personalPackage.selectedCollectionTime;

export const selectNonRestrictDateTimeDateTime = (state) =>
  state.personalPackage.nonRestrictDateTime;

export const selectLabelGenerateStatus = (state) =>
  state.personalPackage.isLabelGenerated;

export const selectLoqalAddressData = (state) =>
  state.personalPackage.loqateData;

export default personalPackageSlice.reducer;
