import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";

// providers
import { Provider } from "react-redux";
import AuthProvider from "./provider/AuthProvider";

// redux store
import { store } from "./app/store";

// components
import App from "./App";

// styles
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
