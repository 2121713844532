import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// utils
import { getCountryNameFromCode } from "../../utils/getPlaces";

const ShippingDetails = ({ shippingErrors, shippingValues, handleChange }) => {
  return (
    <Stack width="100%" direction="column" gap="10px">
      <Typography variant="h4" gutterBottom>
        Receiver / Shipping Details
      </Typography>

      <Grid container columnSpacing={1} rowSpacing={1.5}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.firstName}
            id="shipping.firstName"
            label="First Name"
            helperText={shippingErrors?.firstName}
            value={shippingValues?.firstName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.lastName}
            id="shipping.lastName"
            label="Last Name"
            helperText={shippingErrors?.lastName}
            value={shippingValues?.lastName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.email}
            id="shipping.email"
            label="Email"
            helperText={shippingErrors?.email}
            value={shippingValues?.email}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.phoneNo}
            id="shipping.phoneNo"
            label="Phone"
            helperText={shippingErrors?.phoneNo}
            value={shippingValues?.phoneNo}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!!shippingErrors?.companyName}
            id="shipping.companyName"
            label="Company Name"
            helperText={shippingErrors?.companyName}
            value={shippingValues?.companyName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!shippingErrors?.address1}
            id="shipping.address1"
            label="Address1"
            helperText={shippingErrors?.address1}
            value={shippingValues?.address1}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.city}
            id="shipping.city"
            label="City"
            helperText={shippingErrors?.city}
            value={shippingValues?.city}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.state}
            id="shipping.state"
            label="State"
            helperText={shippingErrors?.state}
            value={shippingValues?.state}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.postalCode}
            id="shipping.postalCode"
            label="Postal Code"
            helperText={shippingErrors?.postalCode}
            value={shippingValues?.postalCode}
            onChange={handleChange}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!shippingErrors?.country}
            id="shipping.country"
            label="Country"
            helperText={shippingErrors?.country}
            value={getCountryNameFromCode(shippingValues?.country)}
            onChange={handleChange}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default ShippingDetails;
