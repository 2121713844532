import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";

// mui
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

// components
import UpdatedPrice from "./UpdatedPrice";
import UpdateConformationModal from "../../Modal/UpdateConformationModal";
import PackageTextFields from "../../PackageDetailsUpdate/PackageTextFields";

// hooks
import useAuth from "../../../hooks/useAuth";

// utils
import { notify } from "../../../utils/globalToast";
import adminAPIRequests from "../../../utils/adminAPIRequests";

// others
import packageValidationSchemas from "../../PackageDetailsPersonal/packagesSchema";

const PackageDetails = ({
  orderId,
  adminInfo,
  packageDetails,
  handleAdminInfo,
  isUKToUKDelivery,
  orderInfoShippingProvider,
  handleUpdateBookingDetailsData,
}) => {
  const { token } = useAuth();

  const [fetchNewPrice, setFetchNewPrice] = useState(null);
  const [isFetchingUpdatedPrice, setIsFetchingUpdatedPrice] = useState(null);
  const [conformationModalIsOpen, setConformationModalIsOpen] = useState(false);
  const [isUpdatingBookingDetails, setIsUpdatingBookingDetails] =
    useState(null);

  const formikData = useFormik({
    validationSchema: Yup.object({
      packages: packageValidationSchemas(),
    }),
    enableReinitialize: true,
    initialValues: packageDetails,
    onSubmit: async (values) => {
      try {
        setFetchNewPrice(null);
        setIsFetchingUpdatedPrice(true);
        setIsUpdatingBookingDetails(null);
        handleAdminInfo({ priceComparision: false, showUpdateButton: false });

        const fetcher = adminAPIRequests({ token });

        const response = await fetcher.post(
          `/booking/updated-price/${orderId}`,
          {
            packages: values?.packages,
          }
        );

        const data = response.data.data;

        setFetchNewPrice(data);
        handleAdminInfo({ priceComparision: true, showUpdateButton: true });

        return data;
      } catch (error) {
        console.log("error-while-getting-package-price", error);
        notify("error", "Cannot get package details. Please try again later");
      } finally {
        setIsFetchingUpdatedPrice(false);
      }
    },
  });

  const { values, errors, submitForm } = formikData;

  const handleOpenConformationModal = () => {
    setConformationModalIsOpen(true);
  };

  const handleCloseConformationModal = () => {
    setConformationModalIsOpen(false);
  };

  const handleUpdateBookingDetails = async () => {
    try {
      setIsUpdatingBookingDetails(true);

      const fetcher = adminAPIRequests({ token });

      const response = await fetcher.patch(`/booking/details/${orderId}`, {
        packages: values?.packages,
        newBookingDetails: fetchNewPrice,
      });

      const data = await response.data;

      handleUpdateBookingDetailsData(data.data);
      handleCloseConformationModal();

      notify("success", "Booking details updated successfully");
      return data;
    } catch (error) {
      notify("error", "Error updating booking details");
    } finally {
      setIsUpdatingBookingDetails(false);
    }
  };

  const handlePackageDimensionsChange = (e, idx) => {
    formikData.setFieldValue(
      "packages",
      formikData.values.packages.map((eachPackage, index) =>
        index === idx
          ? { ...eachPackage, [e.target.name]: e.target.value }
          : eachPackage
      )
    );
  };

  return (
    <>
      <UpdateConformationModal
        isOpen={conformationModalIsOpen}
        handleClose={handleCloseConformationModal}
        confirmationFunc={handleUpdateBookingDetails}
        isConformationLoading={isUpdatingBookingDetails}
      />

      <PackageTextFields
        errors={errors}
        values={values}
        isAdminPage={true}
        handlePackageChange={handlePackageDimensionsChange}
      />

      <Grid container>
        <Grid xs={12} sm={6} md={3} lg={2.4} item>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontWeight: isFetchingUpdatedPrice ? "600" : "",
            }}
            loading={isFetchingUpdatedPrice}
            loadingIndicator={
              <CircularProgress sx={{ color: "grey" }} size={16} />
            }
            endIcon={null}
            loadingPosition="end"
            onClick={submitForm}
          >
            Get updated Price
          </LoadingButton>
        </Grid>
      </Grid>

      {fetchNewPrice &&
        !isFetchingUpdatedPrice &&
        adminInfo?.priceComparision && (
          <UpdatedPrice
            newPrice={fetchNewPrice.price}
            oldPrice={orderInfoShippingProvider.price}
          />
        )}

      {adminInfo?.showUpdateButton && (
        <LoadingButton
          variant="contained"
          color="primary"
          sx={{
            width: { xs: "100%", sm: "30%" },
            fontWeight: isUpdatingBookingDetails ? "600" : "",
          }}
          loading={isUpdatingBookingDetails}
          loadingIndicator={
            <CircularProgress sx={{ color: "grey" }} size={16} />
          }
          endIcon={null}
          loadingPosition="end"
          onClick={handleOpenConformationModal}
        >
          Update Booking Details
        </LoadingButton>
      )}
    </>
  );
};

export default PackageDetails;
