import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  merchantSessionKey: "",
  merchantSessionKeyExpiry: "",
};

export const actionGenerateMerchantSessionKey = createAsyncThunk(
  "payment/generateMerchantSessionKey",
  async (_, thunkAPI) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payment/merchant-session-key`
      );

      return data.data;
    } catch (error) {
      console.log("error-while-getting-merchant-session-key", error);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionGenerateMerchantSessionKey.fulfilled, (state, action) => {
        state.merchantSessionKey = action.payload?.data?.merchantSessionKey;
        state.merchantSessionKeyExpiry = action.payload?.data?.expiry;
        state.isLoading = false;
      })
      .addCase(actionGenerateMerchantSessionKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionGenerateMerchantSessionKey.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectMerchantSessionKey = (state) =>
  state.paymentSlice.merchantSessionKey;
export const selectMerchantSessionKeyExpiry = (state) =>
  state.paymentSlice.merchantSessionKeyExpiry; //Not used currently
export const selectLoadingStatus = (state) => state.paymentSlice.isLoading;

export default paymentSlice.reducer;
