import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Stack";

const UpdatedPrice = ({ oldPrice, newPrice }) => {
  return (
    <Stack>
      <Typography fontWeight="bold" variant="subtitle1">
        The old price was £ {oldPrice}
      </Typography>
      <Typography fontWeight="bold" variant="subtitle1">
        The updated price is £ {newPrice}
      </Typography>
    </Stack>
  );
};

export default UpdatedPrice;
