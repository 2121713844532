import { useFormik } from "formik";
import { useDispatch } from "react-redux";

// redux
import {
  actionGoToNextPage,
  actionUpdateBillingDetails,
  actionUpdateShippingDetails,
} from "../../app/PersonalPackageSlice";

// components
import initialValues from "./InitialFormState";

// validation schema
import validationSchema from "./validation-schemas/addressSchema";

const FormHelper = () => {
  const dispatch = useDispatch();

  const senderAndReceiverForm = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: initialValues(),
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(actionUpdateBillingDetails(values?.billing));
      dispatch(actionUpdateShippingDetails(values?.shipping));
      dispatch(actionGoToNextPage());
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    return senderAndReceiverForm.handleSubmit();
  };

  const handleChange = (e) => {
    senderAndReceiverForm.setFieldValue(e.target.id, e.target.value);
    senderAndReceiverForm.setFieldError(e.target.id, null);
  };

  return {
    handleSubmit,
    handleChange,
    errors: senderAndReceiverForm.errors,
    values: senderAndReceiverForm.values,
  };
};

export default FormHelper;
