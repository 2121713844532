import { Container } from "react-bootstrap";

import ProgressStep from "../ProgressStep/ProgressStep";

import "./QuotesPageContainer.scss";

const QuotesPageContainer = ({
  children,
  currentStep,
  title,
  navigationList,
}) => {
  return (
    <div className="business-quote">
      <Container fluid="lg">
        <div className="business-quote-outer">
          <ProgressStep
            stepIndex={currentStep}
            title={title}
            navigationList={navigationList}
          />
          {children}
        </div>
      </Container>
    </div>
  );
};

export default QuotesPageContainer;
