import React from "react";
import QueryPageForm from "../../components/QueryPageForm/QueryPageForm";

const QueryPage = () => {
  return (
    <>
      <QueryPageForm />
    </>
  );
};

export default QueryPage;
