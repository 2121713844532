import { State, City, Country } from "country-state-city";

export const getCountries = () => {
  const allCountries = Country.getAllCountries();
  return allCountries.map((country) => ({
    label: country?.name,
    value: country?.isoCode,
  }));
};

export const getCountryNameFromCode = (code) => {
  const country = Country.getCountryByCode(code);
  return country.name;
};

export const getStates = (countryCode) => {
  if (!countryCode)
    return [
      {
        label: "",
        value: "",
      },
    ];

  const countryAllStates = State.getStatesOfCountry(countryCode);

  return countryAllStates.map((state) => ({
    label: state?.name,
    value: state?.isoCode,
  }));
};

export const getStateNameFromCountryCode = (stateCode, countryCode) => {
  const state = State.getStateByCodeAndCountry(stateCode, countryCode);
  return !state ? "" : state.name;
};

export const getCities = (countryCode, stateCode) => {
  if (!stateCode || !countryCode)
    return [
      {
        label: "",
        value: "",
      },
    ];

  const countryAllStates = City.getCitiesOfState(countryCode, stateCode);

  return countryAllStates.map((city) => ({
    label: city?.name,
    value: city?.name,
  }));
};

export const cityExistInCountry = () => { };

export const getStatesWithCities = (countryCode) => {
  const states = State.getStatesOfCountry(countryCode);
  const statesWithCities = states.filter((state) => {
    const cities = City.getCitiesOfState(countryCode, state.isoCode);
    return cities.length > 0;
  });
  return statesWithCities.map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));
};