const noPostalCodes = Object.freeze({
  AO: "Angola",
  AG: "Antigua and Barbuda",
  AW: "Aruba",
  BS: "Bahamas",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BO: "Bolivia, Plurinational State of",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BW: "Botswana",
  BF: "Burkina Faso",
  BI: "Burundi",
  CM: "Cameroon",
  CF: "Central African Republic",
  TD: "Chad",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, the Democratic Republic of the",
  CK: "Cook Islands",
  CI: "Cote D'Ivoire (Ivory Coast)",
  CW: "Curaçao",
  DJ: "Djibouti",
  DM: "Dominica",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  FJ: "Fiji",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GH: "Ghana",
  GD: "Grenada",
  GY: "Guyana",
  HM: "Heard Island and McDonald Islands",
  HK: "Hong Kong",
  //   IE: "Ireland", // Ireland came up with Postal code in 13th July 2015(Eircode) but not for whole region?
  KI: "Kiribati",
  KP: "Korea, Democratic People's Republic of",
  LY: "Libya",
  MO: "Macao",
  MW: "Malawi",
  ML: "Mali",
  MR: "Mauritania",
  NR: "Nauru",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NU: "Niue",
  QA: "Qatar",
  RW: "Rwanda",
  KN: "Saint Kitts and Nevis",
  ST: "Sao Tome and Principe",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SX: "Sint Maarten (Dutch part)",
  SB: "Solomon Islands",
  SO: "Somalia",
  SR: "Suriname",
  SY: "Syria",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago", // Planned but not implemented
  TV: "Tuvalu",
  UG: "Uganda",
  AE: "United Arab Emirates",
  VU: "Vanuatu",
  YE: "Yemen",
  ZW: "Zimbabwe",
});

export default noPostalCodes;
