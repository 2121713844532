import { useSearchParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import { MdDone } from "react-icons/md";

// utils
import { routes } from "../../utils/constants";

const ThankYouPage = () => {
  const [queryParameters] = useSearchParams();

  const type = queryParameters.get("type");
  const orderId = queryParameters.get("order_id");
  const billingEmail = queryParameters.get("email");

  const buttonData = {
    query: {
      url: "/",
      text: "Go Back to Home",
    },
    order: {
      url: `${routes.getOrderInfoPage}?order_id=${orderId}`,
      text: "Track your order",
    },
  };

  if (!["order", "query"].includes(type))
    return (
      <Container>
        <Typography
          variant="h5"
          align="center"
          sx={{ my: 2, fontWeight: "bold" }}
        >
          You haven't made any orders yet
        </Typography>
      </Container>
    );

  return (
    <Container>
      <Card sx={{ mt: 2, width: "100%", maxWidth: "600px", mx: "auto" }}>
        <CardContent>
          <Stack direction="column" gap={2} alignItems="flex-start">
            <Stack direction="column" width="100%" alignItems="center" gap={1}>
              <div
                style={{
                  backgroundColor: "green",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                }}
              >
                <MdDone color="white" />
              </div>

              {type === "query" && (
                <Typography variant="h6" textAlign={"center"}>
                  Thank you for your query&#33; We will get back to you soon
                </Typography>
              )}

              {type === "order" && (
                <Typography variant="h4">Thank you for your order</Typography>
              )}
            </Stack>

            {type === "order" && (
              <>
                <Stack direction="column">
                  <Typography variant="subtitle1">
                    <b>Confirmation E-mail sent to:</b> {billingEmail}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>Order No:</b> {orderId} (Store it for future reference)
                  </Typography>
                </Stack>
              </>
            )}

            <Stack width={"100%"} direction={"row"} justifyContent={"center"}>
              <Button
                sx={{
                  // bgcolor: "#003D63",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableElevation
                variant="contained"
                color="primary"
                href={buttonData?.[type]?.url}
              >
                {buttonData?.[type]?.text}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ThankYouPage;
