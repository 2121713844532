import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// redux
import {
  selectBillingDetails,
  selectDeliveryOption,
  selectShippingDetails,
  actionGoToPreviousPage,
  selectCollectionDateTime,
} from "../../app/PersonalPackageSlice";
import {
  selectLoadingStatus,
  actionGenerateMerchantSessionKey,
} from "../../app/PaymentSlice";

// components
import SummaryCard from "./SummaryCard";
import PaymentModal from "../Modal/PaymentModal";
import PopUpModal from "../PopUpModal/PopUpModal";

// utils
import getFormattedDateTime from "../../utils/getFormattedDateTime";

const DeliverySummary = () => {
  const dispatch = useDispatch();

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isGeneratingForm, setIsGeneratingForm] = useState(true);

  const setPaymentModalOpen = () => setIsPaymentModalOpen(true);
  const setPaymentModalClose = () => setIsPaymentModalOpen(false);
  const handlePopUpClose = () => setPopUpOpen(false);

  const goToPreviousPage = () => dispatch(actionGoToPreviousPage());

  const shippingDetails = useSelector(selectShippingDetails);
  const billingDetails = useSelector(selectBillingDetails);
  const selectedDeliveryOptionRedux = useSelector(selectDeliveryOption);
  const collectionDate = useSelector(selectCollectionDateTime);
  const isLoading = useSelector(selectLoadingStatus);

  const loadPaymentsScripts = async () => {
    setPaymentModalOpen();
    try {
      const response = await dispatch(actionGenerateMerchantSessionKey());

      if (response.type === "payment/generateMerchantSessionKey/fulfilled") {
        try {
          setIsGeneratingForm(true);
          window
            .sagepayCheckout({
              merchantSessionKey: response.payload.data.merchantSessionKey,
            })
            .form();
        } catch (error) {
          setPopUpOpen(true);
          console.log(error);
        } finally {
          setTimeout(() => {
            setIsGeneratingForm(false);
          }, 1000);
        }
      }

      if (response.type === "payment/generateMerchantSessionKey/rejected") {
        setPopUpOpen(true);
        console.log("error-while-generate-merchant-sessionKey", response.error);
      }
    } catch (error) {
      setPopUpOpen(true);
      console.log("error-after-generate-merchant-sessionKey", error);
    }
  };

  return (
    <>
      <PopUpModal
        isOpen={popUpOpen}
        handleClose={handlePopUpClose}
        as="BACKEND-ERROR"
      />

      <PaymentModal
        setModalClose={setPaymentModalClose}
        showModal={isPaymentModalOpen}
        isLoading={isLoading}
        isGeneratingForm={isGeneratingForm}
      />

      <Stack
        marginTop="50px"
        marginBottom="50px"
        padding="0 10px"
        gap="20px"
        overflow="hidden"
      >
        <Stack
          direction={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          gap="20px"
        >
          <Stack>
            <Typography variant="h5" component="div">
              Pick Up & Delivery Details
            </Typography>
            <Typography variant="subtitle1" component="div" gutterBottom>
              <span>(Please check details are correct before purchase)</span>
            </Typography>
            <Stack direction={{ sm: "row" }} gap="20px" marginTop={"10px"}>
              <SummaryCard
                type="billing"
                details={billingDetails}
                title="Collection Details"
              />
              <SummaryCard
                type="shipping"
                details={shippingDetails}
                title="Shipping Details"
              />
            </Stack>
          </Stack>
          <Stack>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  Courier Details
                </Typography>

                <Stack direction="row" alignItems="center" gap="5px">
                  <Typography variant="subtitle2" fontWeight="bold">
                    Courier service:
                  </Typography>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "15px",
                      height: "15px",
                    }}
                    className="quote-logo"
                    src={`${
                      selectedDeliveryOptionRedux?.providerCompany === "dhl"
                        ? "/DHL-Logo.png"
                        : selectedDeliveryOptionRedux?.providerCompany ===
                          "indiapost"
                        ? "/indiapost.png"
                        : selectedDeliveryOptionRedux?.providerCompany === "ups"
                        ? "/ups.png"
                        : selectedDeliveryOptionRedux?.providerCompany ===
                            "evri" ||
                          selectedDeliveryOptionRedux?.providerCompany ===
                            "dhl_evri"
                        ? "/evri-logo.png"
                        : ""
                    }`}
                    alt="courier provider logo"
                  />
                  <Typography variant="subtitle2">
                    {selectedDeliveryOptionRedux?.providerName}
                  </Typography>
                </Stack>

                <Typography variant="subtitle2">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Collection date-time:
                  </span>
                  {getFormattedDateTime(collectionDate)}
                </Typography>
                {/* <Typography variant="subtitle2">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    {" "}
                    Delivery option:
                  </span>{" "}
                  {selectedDeliveryOptionRedux?.type}
                </Typography> */}
                <Typography variant="subtitle2">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Delivery type:
                  </span>
                  {selectedDeliveryOptionRedux.providerCompany === "dhl"
                    ? selectedDeliveryOptionRedux?.deliveryType
                    : selectedDeliveryOptionRedux.providerCompany === "dhl_evri"
                    ? selectedDeliveryOptionRedux?.deliveryType
                    : selectedDeliveryOptionRedux.providerCompany === "ups"
                    ? selectedDeliveryOptionRedux.deliveryType
                    : null}
                </Typography>
                <Typography variant="subtitle2">
                  <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                    Time taken:
                  </span>
                  {selectedDeliveryOptionRedux.providerCompany === "dhl"
                    ? selectedDeliveryOptionRedux?.timeTaken
                    : selectedDeliveryOptionRedux.providerCompany === "dhl_evri"
                    ? selectedDeliveryOptionRedux?.timeTaken
                    : selectedDeliveryOptionRedux.providerCompany === "ups"
                    ? "2-3"
                    : "2-3"}
                  day
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Stack>

        <Typography variant="h6" component="div" gutterBottom>
          Total Amount:
          <Typography
            variant="h5"
            component="span"
            sx={{ fontWeight: "bold", ml: 1 }}
          >
            £ {selectedDeliveryOptionRedux.price}
          </Typography>
        </Typography>

        <Stack
          direction={{ md: "row-reverse" }}
          gap="10px"
          justifyContent="center"
        >
          <Button
            type="submit"
            variant="contained"
            color="success"
            onClick={loadPaymentsScripts}
          >
            MAKE PAYMENT
          </Button>
          <Button variant="contained" onClick={goToPreviousPage}>
            CHANGE ADDRESS & OTHER DETAILS
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default DeliverySummary;
