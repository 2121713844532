function getFormattedDateTime(dateStr) {
    const originalDate = new Date(dateStr);

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const formattedDate = `${daysOfWeek[originalDate.getDay()]} ${monthsOfYear[originalDate.getMonth()]} ${originalDate.getDate()} ${originalDate.getFullYear()} ${originalDate.toLocaleTimeString()}`;

    return formattedDate;
}

export default getFormattedDateTime;