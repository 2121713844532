import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// mui
import Typography from "@mui/material/Typography";

// utils
import { routes } from "../utils/constants";

const Copyright = ({ sx }) => {
  return (
    <Typography
      variant="body2"
      display="flex"
      columnGap="5px"
      color="text.secondary"
      justifyContent="center"
      sx={{ ...sx }}
    >
      Copyright ©
      <Link style={{ color: "inherit" }} to={routes.homePage}>
        Impact Express
      </Link>
      {new Date().getFullYear()}.
    </Typography>
  );
};

Copyright.propTypes = {
  sx: PropTypes.shape({}),
};

Copyright.defaultProps = {
  sx: {},
};

export default Copyright;
